import { ElementRolloff, ElementType } from '../core/echoesPlayer'
import { SubscriptionType } from '../core/subscription'

export const siteTitle = 'ECHOES Creator'

export const inputLabelClass = 'text-dark text-uppercase'
export const inputHeadingClass = 'font-weight-bold text-dark text-uppercase'

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const strongPasswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
export const acceptAudioFileTypes = 'audio/*,video/*'

export const statsDateFormat = 'yyyy-MM-dd'

export enum EchoesColours {
  active = '#395fff',
  inactive = '#9caeff',
  selected = '#fbaf21',
  hover = '#395fff'
}

export const buttonIcons = {
  edit: 'echoes-icon echoes-icon-edit',
}

export const pubStatuses: { value: string; label: string }[] = [
  {
    value: 'draft',
    label: 'Draft',
  },
  {
    value: 'private',
    label: 'Private',
  },
  {
    value: 'public',
    label: 'Public',
  },
]

export const editLanguages: { value: string; label: string }[] = [
  {
    value: 'en',
    label: 'EN',
  },
  {
    value: 'de',
    label: 'DE',
  },
  {
    value: 'fr',
    label: 'FR',
  },
  {
    value: 'it',
    label: 'IT',
  },
  {
    value: 'sv',
    label: 'SV'
  }
]

export const subscriptionStatusFilter = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'inactive',
    label: 'Inactive',
  },
]

export const views = [
  {
    value: 'map',
    label: 'Map',
  },
  {
    value: 'list',
    label: 'List',
  },
  {
    value: 'immersive',
    label: 'Immersive',
  },
]

export enum RecurrenceFreqs {
  daily = 'DAILY',
  weekly = 'WEEKLY',
  monthly = 'MONTHLY',
  yearly = 'YEARLY'
}

export const categoryOptionsAdmin = [
  { label: 'featured', value: 'featured' },
]

export const elementTypes = [
  { label: 'sound', value: ElementType.sound },
  { label: 'ambisonic', value: ElementType.ambisonic },
  { label: 'livestream - coming soon', value: ElementType.livestream },
  { label: 'notification - coming soon', value: ElementType.notification },
  { label: 'video - coming soon', value: ElementType.video },

]

export const elementRolloffs = [
  { label: 'inverse', value: ElementRolloff.inverse },
  { label: 'linear', value: ElementRolloff.linear },
  { label: 'linear square', value: ElementRolloff.linearsquare },
  { label: 'inverse tapered', value: ElementRolloff.inversetapered },
]

export const echoLogicRruleFreqs = [
  { label: 'daily', value: 'DAILY' },
  { label: 'weekly', value: 'WEEKLY' },
  { label: 'monthly', value: 'MONTHLY' },
  { label: 'yearly', value: 'YEARLY' },
]

export const echoLogicRruleByDay = [
  { label: 'Monday', value: 'MO' },
  { label: 'Tuesday', value: 'TU' },
  { label: 'Wednesday', value: 'WE' },
  { label: 'Thursday', value: 'TH' },
  { label: 'Friday', value: 'FR' },
  { label: 'Saturday', value: 'SA' },
  { label: 'Sunday', value: 'SU' },
]

export const echoLogicRruleByMonth = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
]

export const notificationTypes = [
  { label: 'Open link', value: 'open-uri' },
  { label: 'Open echo', value: 'open-echo' },
]

export const voiceGroupActionTypes = [
  { label: 'None', value: 'none' },
  { label: 'Duck', value: 'duck' },
  { label: 'Trigger', value: 'trigger' },
  { label: 'Pause', value: 'pause' }
]

export const userCapabilityNames: Record<ICapabilities, string> = {
  'editCollectionSlug': 'Edit collection slug',
  'editMarkdown': 'Edit markdown',
  'editCollectionLatLng': 'Edit collection lat lng',
  'editCollectionPlace': 'Edit collection place',
  'editCollectionTags': 'Edit collection tags',
  'editCollectionChannels': 'Edit collection channels',
  'editCollectionPrivileges': 'Edit collection privileges',
  'editCollectionOnboarding': 'Edit collection onboarding',
  'editCollectionViews': 'Edit collection views',
  'editCollectionLocManuallySet': 'Edit collection location manually set',
  'editCollectionMeta': 'Edit collection meta',
  'editCollectionOptOutOfListenerSubscriptions': 'Edit opting out of subscription',

  'editEchoHideParams': 'Edit all echo hide parameters',
  'editEchoLatLng': 'Edit echo lat lng',
  'editEchoRadius': 'Edit echo radius',
  'editEchoBeacon': 'Edit echo beacon',
  'editEchoLogic': 'Edit echo logic',
  'editEchoHideMarker': 'Edit echo hide marker',

  'editAllEchoLogicConditions': 'Edit all echo logic conditions',
  'editEchoCustomLogicConditions': 'Edit custom echo logic conditions',

  'editSpatialAudio': 'All spatial audio features',

  'convertSounds': 'Convert sounds',
  'uploadAnySoundMimeType': 'Upload any sound mime type',
  'addMultipleElements': 'Add multiple elements',

  'editFadeInMS': 'Edit fade in ms',
  'editFadeOutMS': 'Edit fade out ms',
  'editElementResume': 'Edit element resume',
  'editLiveStreamSound': 'Edit livestream sound',
  'editVideo': 'Edit video',
  'editNotificationElement': 'Edit notification elements',
  'editElementHref': 'Edit element href',
  'changeLanguage': 'Change language',
  'importGeoJsonFiles': 'Import geo json files',
  'editTrajectoryPubStatus': 'Edit trajectory pub status',
  'editCollectionCommerce':
    'Edit collection in-app purchase information',
  'editTrajectoryTitle': 'Edit line titles',
  'editProfilePrivileges': 'Edit Profile Privileges',
  'editElementHideParams': 'Edit all element hide parameters',
  'editElementSync': 'Edit element sync',
  'editElementQueue': 'Edit element queues',
  'editElementVoiceGroups': 'Edit element voice group',
  'editElementTimeSyncGroups': 'Edit element time sync group',
  'editElementTitle': 'Edit element title',
  'editElementMaxVolume': 'Edit element max volume',
  'editElementDescription': 'Edit element description',
  'editCollectionIntro': 'Edit walk intro',

  'viewStats': 'View stats',
  'useSimulator': 'Use simulator',
  'exportCollection': 'Export collection',
  'duplicateCollection': 'Duplicate collection'
}

export const subscriptionCapabilities: Record<SubscriptionType, Record<ICapabilities, boolean>> = {
  [SubscriptionType.none]: {
    'editMarkdown': false,
    'editElementResume': false,
    'editCollectionSlug': false,
    'editCollectionLatLng': false,
    'editCollectionPlace': false,
    'editCollectionTags': false,
    'editCollectionChannels': false,
    'editCollectionPrivileges': false,
    'editCollectionOnboarding': false,
    'editCollectionViews': false,
    'editCollectionMeta': false,
    'editCollectionLocManuallySet': false,
    'editEchoHideParams': false,
    'editEchoLatLng': false,
    'editEchoRadius': false,
    'editEchoBeacon': false,
    'editEchoLogic': false,
    'editEchoHideMarker': false,
    'editAllEchoLogicConditions': false,
    'editEchoCustomLogicConditions': false,
    'convertSounds': true,
    'editSpatialAudio': false,
    'addMultipleElements': false,
    'editFadeInMS': false,
    'editFadeOutMS': false,
    'uploadAnySoundMimeType': true,
    'editLiveStreamSound': false,
    'editVideo': false,
    'editNotificationElement': false,
    'editElementHref': false,
    'changeLanguage': false,
    'importGeoJsonFiles': false,
    'editTrajectoryPubStatus': false,
    'editCollectionCommerce': false,
    'editTrajectoryTitle': false,
    'editProfilePrivileges': false,
    'editElementHideParams': false,
    'editElementSync': false,
    'editElementQueue': false,
    'editElementVoiceGroups': false,
    'editElementTimeSyncGroups': false,
    'editElementTitle': false,
    'editElementMaxVolume': false,
    'editElementDescription': false,
    'editCollectionIntro': false,
    'viewStats': false,
    'useSimulator': false,
    'exportCollection': false,
    'duplicateCollection': false,
    'editCollectionOptOutOfListenerSubscriptions': false
  },
  [SubscriptionType.essential]: {
    'editMarkdown': true,
    'editElementResume': true,
    'editCollectionSlug': false,
    'editCollectionLatLng': false,
    'editCollectionPlace': false,
    'editCollectionTags': false,
    'editCollectionChannels': false,
    'editCollectionPrivileges': false,
    'editCollectionOnboarding': false,
    'editCollectionViews': false,
    'editCollectionMeta': false,
    'editCollectionLocManuallySet': false,
    'editEchoHideParams': true,
    'editEchoLatLng': true,
    'editEchoRadius': false,
    'editEchoBeacon': false,
    'editEchoLogic': true,
    'editEchoHideMarker': false,
    'editAllEchoLogicConditions': false,
    'editEchoCustomLogicConditions': false,
    'convertSounds': true,
    'editSpatialAudio': true,
    'addMultipleElements': true,
    'editFadeInMS': true,
    'editFadeOutMS': true,
    'uploadAnySoundMimeType': true,
    'editLiveStreamSound': false,
    'editVideo': false,
    'editNotificationElement': false,
    'editElementHref': false,
    'changeLanguage': false,
    'importGeoJsonFiles': true,
    'editTrajectoryPubStatus': false,
    'editCollectionCommerce': false,
    'editTrajectoryTitle': true,
    'editProfilePrivileges': true,
    'editElementHideParams': false,
    'editElementSync': true,
    'editElementQueue': true,
    'editElementVoiceGroups': true,
    'editElementTimeSyncGroups': false,
    'editElementTitle': true,
    'editElementMaxVolume': false,
    'editElementDescription': false,
    'editCollectionIntro': true,
    'viewStats': true,
    'useSimulator': false,
    'exportCollection': false,
    'duplicateCollection': false,
    'editCollectionOptOutOfListenerSubscriptions': false
  },
  [SubscriptionType.extra]: {
    'editMarkdown': true,
    'editElementResume': true,
    'editCollectionSlug': false,
    'editCollectionLatLng': true,
    'editCollectionPlace': true,
    'editCollectionTags': true,
    'editCollectionChannels': false,
    'editCollectionPrivileges': false,
    'editCollectionOnboarding': true,
    'editCollectionViews': true,
    'editCollectionMeta': true,
    'editCollectionLocManuallySet': false,
    'editEchoHideParams': true,
    'editEchoLatLng': true,
    'editEchoRadius': true,
    'editEchoBeacon': true,
    'editEchoLogic': true,
    'editEchoHideMarker': false,
    'editAllEchoLogicConditions': false,
    'editEchoCustomLogicConditions': false,
    'convertSounds': true,
    'editSpatialAudio': true,
    'addMultipleElements': true,
    'editFadeInMS': true,
    'editFadeOutMS': true,
    'uploadAnySoundMimeType': true,
    'editLiveStreamSound': false,
    'editVideo': false,
    'editNotificationElement': false,
    'editElementHref': false,
    'changeLanguage': false,
    'importGeoJsonFiles': true,
    'editTrajectoryPubStatus': true,
    'editCollectionCommerce': false,
    'editTrajectoryTitle': true,
    'editProfilePrivileges': true,
    'editElementHideParams': false,
    'editElementSync': true,
    'editElementQueue': true,
    'editElementVoiceGroups': true,
    'editElementTimeSyncGroups': false,
    'editElementTitle': true,
    'editElementMaxVolume': false,
    'editElementDescription': false,
    'editCollectionIntro': true,
    'viewStats': true,
    'useSimulator': false,
    'exportCollection': false,
    'duplicateCollection': false,
    'editCollectionOptOutOfListenerSubscriptions': true
  },
}

export const roles = [
  { value: 'admin', label: 'Admin' },
  {
    value: 'user',
    label: 'User',
  },
]

export const echoesConditionAndOr = [
  {
    value: '_or',
    label: 'OR',
  },
  {
    value: '_and',
    label: 'AND',
  },
]

export const echoesConditionGtLt = [
  {
    value: '_gt',
    label: 'Greater than'
  },
  {
    value: '_lt',
    label: 'Less than'
  }
]

export const echoesConditionOptions: {
  value: AllLogicConditionKey,
  label: string
}[] = [
    { value: '_echo_has_played', label: 'Echo has played' },
    { value: '_echo_has_triggered', label: 'Echo has triggered' },
    { value: '_echo_has_not_played', label: 'Echo has not played' },
    { value: '_echo_has_not_triggered', label: 'Echo has not triggered' },
    { value: '_recurrence_is_after', label: 'Time/date is after' },
    { value: '_recurrence_is_before', label: 'Time/date is before' },
    { value: '_echo_has_elapsed_ms', label: 'Has played a certain number of seconds' },
  ]

export const statsIntervals: { value: WalkStatsIntervalSelect, label: string }[] = [
  { value: 'hour', label: 'Hourly' },
  { value: 'day', label: 'Daily' },
  { value: 'week', label: 'Weekly' },
  { value: 'month', label: 'Monthly' },
]

export const stateDateFromType: { value: WalkStatsRangeSelect, label: string }[] = [
  { value: '-24h', label: 'Last 24 hours' },
  { value: '-48h', label: 'Last 48 hours' },
  { value: '-7d', label: 'Last week' },
  { value: '-14d', label: 'Last two weeks' },
  { value: '-30d', label: 'Last 30 days' },
  { value: '-90d', label: 'Last 90 days' },
  { value: 'mStart', label: 'This month' },
  { value: 'yStart', label: 'Year to date' },
  { value: 'daterange', label: 'Date range' }
]

export const syncGroups = [
  { label: 'No sync group', value: -1 },
  { label: 1, value: 0 },
  { label: 2, value: 1 },
  { label: 3, value: 2 },
  { label: 4, value: 3 },
  { label: 5, value: 4 },
  { label: 6, value: 5 },
  { label: 7, value: 6 },
  { label: 8, value: 7 },
  { label: 9, value: 8 },
  { label: 10, value: 9 },
]

export const echoQueues = [
  { label: 'No queuing', value: -1 },
  { label: '1', value: 0 },
  { label: '2', value: 1 },
  { label: '3', value: 2 },
  { label: '4', value: 3 },
  { label: '5', value: 4 },
  { label: '6', value: 5 },
  { label: '7', value: 6 },
  { label: '8', value: 7 },
  { label: '9', value: 8 },
  { label: '10', value: 9 },
]

export const elementQueues = [
  { label: 'No queuing', value: -1 },
  { label: '1', value: 0 },
  { label: '2', value: 1 },
  { label: '3', value: 2 },
  { label: '4', value: 3 },
  { label: '5', value: 4 },
  { label: '6', value: 5 },
  { label: '7', value: 6 },
  { label: '8', value: 7 },
  { label: '9', value: 8 },
  { label: '10', value: 9 },
]

export const echoQueuePriorities = [
  { label: '1', value: 0 },
  { label: '2', value: 1 },
  { label: '3', value: 2 },
  { label: '4', value: 3 },
  { label: '5', value: 4 },
  { label: '6', value: 5 },
  { label: '7', value: 6 },
  { label: '8', value: 7 },
  { label: '9', value: 8 },
  { label: '10', value: 9 },
]

export const elementQueuePriorities = [
  { label: '1', value: 0 },
  { label: '2', value: 1 },
  { label: '3', value: 2 },
  { label: '4', value: 3 },
  { label: '5', value: 4 },
  { label: '6', value: 5 },
  { label: '7', value: 6 },
  { label: '8', value: 7 },
  { label: '9', value: 8 },
  { label: '10', value: 9 },
]
