export class i18nText implements Ii18nText {
  lang = 'en'
  text = ''
}

export function getText(obj: i18nText[], lang = 'en'): string {
  if (obj && obj.length > 0) {
    const o = obj.find(o => o.lang === lang)
    return o ? o.text : ''
  } return ''
}

export function getTextIndex(obj: i18nText[], lang = 'en'): number {
  if (obj && obj.length > 0) {
    return obj.findIndex(o => o.lang === lang)
  } return -1
}

export function setText(text: string, obj: i18nText[], lang = 'en'): i18nText[] {
  if (obj) {
    const i = obj.findIndex(o => o.lang === lang)
    if (i === -1) {
      // no match found for the language, we push instead
      obj.push({ text, lang })
    } else {
      obj[i].text = text
    }
  }
  return obj
}
