import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { instance } from '../axios'

export default {
  getAll(params: IWalkQueryParams): Promise<AxiosResponse<ICollectionModel[]>> {
    return instance.get<ICollectionModel[]>('/api/v2/collections', {
      params,
    })
  },
  getMine(params: IWalkQueryParams): Promise<AxiosResponse<ICollectionModel[]>> {
    return instance.get<ICollectionModel[]>('/api/v2/collections/mine', {
      params,
    })
  },
  get(slug: string): Promise<AxiosResponse<ICollectionModel>> {
    return instance.get<ICollectionModel>(`/api/v2/collections/${slug}`)
  },
  getChannels(s?: string): Promise<AxiosResponse<string[]>> {
    const params: { s?: string } = {}
    if (s) {
      params.s = s
    }
    return instance.get<string[]>(`/api/v2/collections/channels`, { params })
  },
  create(collection: ICollectionModel | INewCollectionModel, duplicate = false): Promise<AxiosResponse<ICollectionModel>> {
    const config: AxiosRequestConfig = {}
    if (duplicate) {
      config.params = {
        duplicate: true
      }
    }
    return instance.post<ICollectionModel>('/api/v2/collections/', collection, config)
  },
  update(collection: ICollectionModel): Promise<AxiosResponse<ICollectionModel>> {
    return instance.put<ICollectionModel>(`/api/v2/collections/${collection.slug}`, collection)
  },
  delete(slug: string): Promise<AxiosResponse> {
    return instance.delete(`/api/v2/collections/${slug}`)
  },
}
