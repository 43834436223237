import { Reducer } from 'redux'
import {
  GOT_SELF,
  GETTING_USERS,
  GOT_USERS,
  ERROR_GETTING_USERS,
  GETTING_USER,
  GOT_USER,
  ERROR_GETTING_USER,
  CREATE_USER,
  UPDATE_SELF,
  UPDATE_USER,
  CHANGE_LANG
} from '../constants/user.constants'
import { Role } from '../core/user'

const initialState: IUserState = {
  self: {
    name: '',
    email: '',
    description: [],
    role: Role.user,
    capabilities: [],
    email_optin: false
  },
  user: {
    name: '',
    email: '',
    description: [],
    role: Role.user,
    capabilities: [],
    email_optin: false
  },
  users: [],
  count: 0,
  lang: 'en',
  userDirty: false,
  gettingUser: false,
  gettingUsers: false,
  gettingUsersError: false,
  gettingUserError: false,
  errorMessage: '',
  initialValues: {
    username: '',
    password: '',
  },
}

const auth: Reducer<IUserState> = (state = initialState, action): IUserState => {
  switch (action.type) {
    case GOT_SELF: {
      return {
        ...state,
        self: action.payload.user,
      }
    }
    case GETTING_USERS: {
      return {
        ...state,
        gettingUsers: true,
        gettingUsersError: false,
      }
    }
    case GOT_USERS:
      return {
        ...state,
        users: action.payload.users,
        count: action.payload.count,
        gettingUsers: false,
        gettingUsersError: false,
      }
    case ERROR_GETTING_USERS:
      return {
        ...state,
        gettingUsers: false,
        gettingUsersError: true,
        errorMessage: action.payload,
      }
    case GETTING_USER:
      return {
        ...state,
        gettingUser: true,
        gettingUserError: false,
      }
    case GOT_USER: {
      return {
        ...state,
        user: action.payload.user,
        gettingUser: false,
        gettingUserError: false,
      }
    }
    case ERROR_GETTING_USER:
      return {
        ...state,
        gettingUser: false,
        gettingUserError: true,
        errorMessage: action.payload,
      }
    case CREATE_USER: {
      return {
        ...state,
        self: action.payload.user,
        userDirty: true,
      }
    }
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      }
    case UPDATE_SELF: {
      return {
        ...state,
        self: action.payload.user,
        userDirty: false,
      }
    }
    case CHANGE_LANG: {
      return {
        ...state,
        lang: action.payload
      }
    }
    default:
      return state
  }
}

export default auth
