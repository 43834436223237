import React from 'react'
import Lottie from 'lottie-react'
import cx from 'classnames'
import echoesSpinner from './echoes-spinner.json'

const Spinner: React.FC<ISpinnerProps> = ({ xs, sm, className, active }) => {
  return (
    <div
      className={cx(
        'spinner-basic',
        {
          'spinner-sm': sm,
          'spinner-xs': xs,
        },
        className
      )}
    >
      <Lottie animationData={echoesSpinner} loop={!active} />
    </div>
  )
}

export default Spinner
