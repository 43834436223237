import { Action, Reducer } from 'redux'
import * as mapConstants from '../constants/map.constants'

const initialState: IMapState = {
  viewport: {
    center: [52, 1],
    zoom: 13,
  },
  currentLocation: [0, 1],
  mapVisible: true,
  mapNeedsRefresh: false,
  mapFullscreen: false,
  mapLayer: 0,
}

// type MapActions = keyof typeof mapConstants

const map: Reducer<IMapState, Action<string>> = (state = initialState, action) => {
  switch (action.type) {
    case mapConstants.CHANGE_MAP_VIEWPORT:
      return {
        ...state,
        mapNeedsRefresh: false,
        viewport: {
          ...state.viewport,
          ...action.payload,
        },
      }
    case mapConstants.SET_USER_GEOLOCATION:
      return {
        ...state,
        mapNeedsRefresh: false,
        currentLocation: action.payload,
      }
    case mapConstants.TOGGLE_MAP_VISIBLE: {
      const mapNeedsRefresh = !state.mapVisible
      return {
        ...state,
        mapVisible: !state.mapVisible,
        mapFullscreen: state.mapVisible ? false : state.mapFullscreen,
        mapNeedsRefresh,
      }
    }
    case mapConstants.TOGGLE_MAP_FULLSCREEN:
      return {
        ...state,
        mapNeedsRefresh: true,
        mapFullscreen: !state.mapFullscreen,
      }
    case mapConstants.SELECT_MAP_LAYER:
      return {
        ...state,
        mapNeedsRefresh: true,
        mapLayer: action.payload,
      }
    default:
      return state
  }
}

export default map
