import { subDays } from 'date-fns'
import { Reducer } from 'redux'

import * as appConfigConstants from '../constants/appConfig.constants'

const initialState = {
    config: undefined,
    gettingAppConfig: false,
    lastFetchedAppConfig: subDays(new Date(), 7),
    error: undefined
}

/* type AppConfigActions = keyof typeof appConfigConstants
interface AppConfigAction<T> extends Action<T> {
    payload: any
} */

const appConfig: Reducer<IAppConfigState> = (state = initialState, action): IAppConfigState => {
    switch (action.type) {
        case appConfigConstants.GETTING_APP_CONFIG:
            return {
                ...state,
                gettingAppConfig: true
            }
        case appConfigConstants.GOT_APP_CONFIG:
            return {
                ...state,
                config: action.payload,
                lastFetchedAppConfig: new Date(),
                gettingAppConfig: false
            }
        case appConfigConstants.ERROR_GETTING_APP_CONFIG:
            return {
                ...state,
                error: action.payload,
                gettingAppConfig: true
            }
        case appConfigConstants.RESET_APP_CONFIG:
            return {
                ...state,
                gettingAppConfig: false,
                config: undefined,
                error: undefined,
                lastFetchedAppConfig: subDays(new Date(), 7)
            }
        default:
            return state
    }
}

export default appConfig
