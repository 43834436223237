export enum Role {
  admin = 'admin',
  user = 'user'
}

export const RoleTypes = [
  {
    value: '',
    label: 'No role',
  },
  {
    value: 'admin',
    label: 'admin',
  },
  {
    value: 'user',
    label: 'user'
  }
]
