import {
  GETTING_USER_PROFILE,
  GOT_USER_PROFILE,
  ERROR_GETTING_USER_PROFILE,
  UPDATING_USER_PROFILE,
  UPDATED_USER_PROFILE,
  ERROR_UPDATING_USER_PROFILE,
} from '../constants/userProfile.constants'
import { Role } from '../core/user'
import { Reducer } from 'redux'

const initialState: IUserProfileState = {
  gettingUserProfile: false,
  updatingUserProfile: false,
  gettingUserProfileError: false,
  updatingUserProfileError: false,
  errorMessage: '',
  initialValues: {
    name: '',
    email: '',
    description: [],
    role: Role.user,
    capabilities: [],
    email_optin: false
  }
}

const auth: Reducer<IUserProfileState> = (state = initialState, action): IUserProfileState => {
  switch (action.type) {
    case GETTING_USER_PROFILE:
      return {
        ...state,
        gettingUserProfile: true,
        gettingUserProfileError: false,
        initialValues: initialState.initialValues,
      }
    case GOT_USER_PROFILE:
      return {
        ...state,
        gettingUserProfile: false,
        gettingUserProfileError: false,
        initialValues: action.payload,
      }
    case ERROR_GETTING_USER_PROFILE:
      return {
        ...state,
        gettingUserProfile: false,
        gettingUserProfileError: true,
        errorMessage: action.payload,
        initialValues: initialState.initialValues,
      }
    case UPDATING_USER_PROFILE:
      return {
        ...state,
        updatingUserProfile: true,
        updatingUserProfileError: false,
      }
    case UPDATED_USER_PROFILE:
      return {
        ...state,
        updatingUserProfile: false,
        updatingUserProfileError: false,
        initialValues: action.payload,
      }
    case ERROR_UPDATING_USER_PROFILE:
      return {
        ...state,
        updatingUserProfile: false,
        updatingUserProfileError: true,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default auth
