import EchoesEngine from './echoesEngine'

export enum DownloadStatus {
  stream = 'stream',
  downloading = 'downloading',
  downloaded = 'downloaded',
}

export enum ElementType {
  sound = 'sound',
  ambisonic = 'ambisonic',
  livestream = 'livestream',
  notification = 'notification',
  video = 'video',
}

export enum ElementRolloff {
  inverse = 'inverse',
  linear = 'linear',
  linearsquare = 'linearsquare',
  inversetapered = 'inversetapered'
}

export enum ElementNotificationType {
  openUri = 'open-uri',
  openEcho = 'open-echo',
}

enum FadeFinishAction {
  pause = 0,
  stop = 1
}

export class EchoesPlayer {

  private audioContext?: AudioContext
  playerElement?: HTMLAudioElement
  private playerSource?: MediaElementAudioSourceNode
  private foaRenderer?: FOARenderer

  gain = 1.0

  playedCount = 0
  playOnce = false
  playComplete = false
  playLoop = false
  resume = false
  fadeOutMs = 500

  volumeDecrement = 0.1
  fadeOutTimer?: number

  offline = false

  gainUpdateTimerStep?: number
  gainIncrement = 0
  volumeChangeInterval = 50 // in ms

  constructor(element: IElementModel) {
    // borrow the audio context from the Echoes Engine
    this.audioContext = EchoesEngine.shared.audioContext
    // create an audio element

    if (this.audioContext) {
      // create a media element source
      if (element.slug) {
        const playerElement = document.getElementById(element.slug) as HTMLAudioElement
        if (playerElement) {
          this.playerElement = playerElement
          this.playerElement.src = (element.media && element.media.href) || ''

        } else {
          this.playerElement = new Audio()
          this.playerElement.id = element.slug
          this.playerElement.src = (element.media && element.media.href) || ''
          this.playerSource = this.audioContext.createMediaElementSource(this.playerElement)
        }
      }
      if (this.playerElement) {
        if (this.playerSource) {
          // ambisonics
          if (element.type === ElementType.ambisonic) {
            // set up foa
            // @TODO: get omnitone working
            // this.foaRenderer = omnitone.createFOARenderer(this.audioContext)
            this.foaRenderer?.initialize()
            if (this.foaRenderer?.input) {
              this.playerSource.connect(this.foaRenderer?.input)
              this.foaRenderer?.output.connect(this.audioContext.destination)
              this.audioContext.resume()
            }
          } else {
            this.playerSource.connect(this.audioContext.destination)
          }
        }
        this.playerElement?.addEventListener('ended', this.playerDidFinishPlaying)
      }
      this.setPlayerGain(this.gain)
      this.playOnce = element.play_once
      this.playComplete = element.play_complete
      this.playLoop = element.play_loop
      this.resume = element.resume
      this.fadeOutMs = element.fade_out_ms
      if (this.playLoop) {
        this.setToLoop()
      }
    }
  }

  shouldPlay(): boolean {
    return !this.isPlaying() && !(this.playOnce && this.playedCount >= 1)
  }

  shouldStop(force: boolean): boolean {
    if (this.isPlaying()) {
      if (force) {
        return true
      }
      // only return true for playComplete if there's no loop, otherwise it'll keep playing forever
      if (this.playComplete && !this.playLoop) {
        return false
      }
      return true
    } else {
      return false
    }
  }

  async play(): Promise<void> {
    return this.playerElement?.play()
  }

  pauseOrStop(force = false): void {
    if (this.resume) {
      this.pause(force)
    } else {
      this.stop(force)
    }
  }

  pause(force = false): void {
    if (this.fadeOutMs > 0.0 && !force) {
      // get the amount by which to decrement the volume each cycle
      let volume = 1.0
      volume = this.playerElement?.volume || 1.0
      this.volumeDecrement = volume / (this.fadeOutMs / this.volumeChangeInterval)
      this.fadeOutAnd(FadeFinishAction.pause)
    } else {
      // force = true
      this.playerElement?.pause()
    }
  }

  stop(force = false, fade?: number): void {
    if (this.fadeOutMs > 0.0 && !force) {
      // get the amount by which to decrement the volume each cycle
      let volume = 1.0
      volume = this.playerElement?.volume || 1.0
      this.volumeDecrement = volume / (this.fadeOutMs / this.volumeChangeInterval)
      this.fadeOutAnd(FadeFinishAction.stop)
    } else {
      // force = true
      if (this.playerElement && fade && fade > 0.0) {
        const volume = this.playerElement?.volume
        this.volumeDecrement = volume / (this.fadeOutMs / this.volumeChangeInterval)
        this.playerElement.currentTime = 0.0
        this.fadeOutAnd(FadeFinishAction.stop)
      } else if (this.playerElement) {
        this.playerElement.pause()
        this.playerElement.currentTime = 0.0
      }
    }
  }

  /**
   * Set the current position of the audio
   * @param percentage Should be between 0.0-1.0
   */
  setCurrentPosition(percentage: number): void {
    const duration = this.playerElement?.duration || 1.0
    if (isNaN(duration) || duration > 10000) {
      const seekTo = duration * percentage
      if (this.playerElement) {
        this.playerElement.currentTime = seekTo
      }
    }
  }

  playerDidFinishPlaying(): void {
    if (this.playOnce) {
      // it's set to playOnce and has finished playing, we increment the play count
      this.incrementPlayCount()
    }
  }

  fadeOutAnd(action: FadeFinishAction = FadeFinishAction.pause): void {
    this.fadeOutTimer = window.setInterval(() => {
      const volume = this.playerElement?.volume || 1.0
      if (this.playerElement && volume >= this.volumeDecrement) {
        const newVolume = volume - this.volumeDecrement
        this.playerElement.volume = newVolume
      } else if (this.playerElement) {
        this.playerElement.pause()
        if (action === FadeFinishAction.stop) {
          this.playerElement.currentTime = 0.0
        }
        window.clearInterval(this.fadeOutTimer)
      }
    }, this.volumeChangeInterval)
  }

  isPlaying(): boolean {
    return !this.playerElement?.paused || false
  }

  setPlayComplete(): void {
    this.playComplete = true
  }

  setToLoop(): void {
    if (this.playerElement) {
      this.playerElement.loop = true
    }
  }

  /**
   * Set the player gain over a given duration
   * @param gain Volume 0.0-1.0
   * @param updateDuration Duration over which to update the gain, in ms
   */
  setPlayerGain(gain: number, updateDuration = 0.0): void {
    if (updateDuration > 2) {
      updateDuration = 2
    }
    const oldGain = this.gain
    this.gain = gain
    // invalidate the old timers
    if (this.gainUpdateTimerStep !== null) {
      window.clearInterval(this.gainUpdateTimerStep)
    }
    if (this.playerElement && updateDuration === 0.0) {
      this.playerElement.volume = gain
    } else if (this.playerElement) {
      // step interval
      const step = 10 // ms
      // work out the number of steps
      const gainSteps = updateDuration / step
      // work out the amount to increment each step
      this.gainIncrement = (oldGain - gain) / gainSteps
      this.gainUpdateTimerStep = window.setInterval(() => {
        if (this.playerElement) {
          // work out the target gain
          const targetGain = this.playerElement.volume + this.gainIncrement
          // make sure the gain is between 0 and 1
          this.playerElement.volume = Math.max(0.0, Math.min(1.0, targetGain))
        }
      }, updateDuration)
    }
  }

  incrementPlayCount(): void {
    this.playedCount++
  }

  getProgressTime(): number {
    return this.playerElement?.currentTime || 0.0
  }

  getTotalTime(): number {
    return this.playerElement?.duration || 1.0 // in seconds
  }
}
