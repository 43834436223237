/* PROFILE */
export const GETTING_PROFILES = 'GETTING_PROFILES'
export const GOT_PROFILES = 'GOT_PROFILES'
export const ERROR_GETTING_PROFILES = 'ERROR_GETTING_PROFILES'

export const GETTING_ADMIN_PROFILES = 'GETTING_ADMIN_PROFILES'
export const GOT_ADMIN_PROFILES = 'GOT_ADMIN_PROFILES'
export const ERROR_GETTING_ADMIN_PROFILES = 'ERROR_GETTING_ADMIN_PROFILES'

export const GETTING_PROFILE = 'GETTING_PROFILE'
export const GOT_PROFILE = 'GOT_PROFILE'
export const ERROR_GETTING_PROFILE = 'ERROR_GETTING_PROFILE'

export const NEW_PROFILE = 'NEW_PROFILE'

export const CREATING_PROFILE = 'CREATING_PROFILE'
export const CREATE_PROFILE = 'CREATE_PROFILE'
export const ERROR_CREATING_PROFILE = 'ERROR_CREATING_PROFILE'

export const GETTING_COLLECTIONS_FOR_PROFILE = 'GETTING_COLLECTIONS_FOR_PROFILE'
export const GOT_COLLECTIONS_FOR_PROFILE = 'GOT_COLLECTIONS_FOR_PROFILE'
export const ERROR_GETTING_COLLECTIONS_FOR_PROFILE = 'ERROR_GETTING_COLLECTIONS_FOR_PROFILE'

export const UPDATING_PROFILE = 'UPDATING_PROFILE'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const ERROR_UPDATING_PROFILE = 'ERROR_UPDATING_PROFILE'

export const DELETING_PROFILE = 'DELETING_PROFILE'
export const DELETE_PROFILE = 'DELETE_PROFILE'
export const ERROR_DELETING_PROFILE = 'ERROR_DELETING_PROFILE'

export const OPEN_ACTIVATE_PROFILE_MODAL = 'OPEN_ACTIVATE_PROFILE_MODAL'
export const CLOSE_ACTIVATE_PROFILE_MODAL = 'CLOSE_ACTIVATE_PROFILE_MODAL'

export const DISMISS_PROFILE_ERROR = 'DISMISS_PROFILE_ERROR'
