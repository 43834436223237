import { center, booleanPointInPolygon, distance, lineToPolygon, point, lineString } from '@turf/turf'

import { getText } from './i18n'
import { getMediaHref } from './helpers'
import { EchoesPlayer } from './echoesPlayer'

export class ZoneColours {
  fillColor: string
  strokeColor: string
  opacity: number

  constructor(fillColor: string, strokeColor: string, opacity = 1.0) {
    this.fillColor = fillColor
    this.strokeColor = strokeColor
    this.opacity = opacity
  }
}

export enum Shape {
  circle = 'Circle',
  polygon = 'Polygon'
}

export enum EchoStatus {
  /// The echo has not been triggered
  inactive,
  /// The echo has been triggered
  active
}

export enum EchoLocationStatus {
  /// We're outside the echo
  outside,
  /// We're inside the echo
  inside
}

export enum TriggerType {
  location = 'location',
  beacon = 'beacon',
  logic = 'logic'
}

enum ZoneColour {
  active = '#395fff',
  inactive = '#9caeff',
  selected = '#fbaf21'
}

export class Echo implements IEcho {
  _id?: string

  model: IEchoModel

  polygon?: GeoJSON.Feature<GeoJSON.Polygon>
  polygonCentre?: GeoJSON.Feature<GeoJSON.Point>

  centreCoords: GeoJSON.Feature<GeoJSON.Point>

  statusHasChanged = false
  selected = false
  status: EchoStatus = EchoStatus.inactive
  locStatus: EchoLocationStatus = EchoLocationStatus.outside

  players: EchoesPlayer[] = []

  titleText = ''
  descriptionText = ''
  coverHref = ''
  spatialization = false

  queue = -1
  queue_priority = -1
  dequeue_on_trigger = false

  isLoaded = false
  offline = false

  triggeredCount = 0
  playedCount(): number {
    if (this.players.length === 0) { return 0 }
    return this.players.map(p => p.playedCount).reduce((prev, next) => prev + next, 0)
  }

  constructor(echo: IEchoModel, offline: boolean) {
    this.model = echo
    this.offline = offline
    // initialise centre coords
    this.centreCoords = point(echo.loc?.coordinates as number[])
    // initialise title
    this.titleText = getText(echo.title)
    // initialise description
    this.descriptionText = getText(echo.description)
    // initialise cover href
    this.coverHref = getMediaHref(echo.media)

    if (echo.shape === Shape.polygon) {
      // initialise polygon
      if (echo.polygon) {
        const line = lineString(echo.polygon.coordinates[0])
        const poly = lineToPolygon(line)
        if (poly) {
          this.polygon = poly as GeoJSON.Feature<GeoJSON.Polygon>
        }
        this.setPolygonCentre()
      }
    }

    // set spatialization if any elements have it
    this.spatialization = this.model.elements.map(el => el.spatialization).reduce((prev, el) => prev || el, false)
  }

  getSounds(): SoundsModel[] {
    if (!this.model.elements) {
      return []
    }
    const soundsModels: SoundsModel[] = []
    for (const element of this.model.elements) {
      const soundModel: SoundsModel = {
        mediaHref: (element.media && element.media.href) || '',
        element
      }
      soundsModels.push(soundModel)
    }
    return soundsModels
  }

  loadPlayers(): void {
    if (!this.isLoaded) {
      const allSounds: SoundsModel[] = this.getSounds()
      allSounds.forEach((soundModel, i) => {
        this.players[i] = new EchoesPlayer(soundModel.element)
      })
      this.isLoaded = true
    }
  }

  unloadPlayers(): void {
    this.players = []
    this.isLoaded = false
  }

  isPolygon(): boolean {
    return this.model.shape === Shape.polygon
  }

  /**
   * Determine if a particular location is inside this Echo's shape
   */
  locationIsInside(location: GeoJSON.Feature<GeoJSON.Point>): boolean {
    if (this.isPolygon() && this.polygon) {
      return booleanPointInPolygon(location, this.polygon)
    } else {
      const distanceToCentreKm = distance(location, this.centreCoords)
      return (distanceToCentreKm * 1000) < this.model.radius
    }
  }

  setPolygonCentre(): void {
    if (this.polygon) {
      this.polygonCentre = center(this.polygon)
    }
  }

  shouldSetZoneColours(): boolean {
    if (this.statusHasChanged) {
      this.statusHasChanged = false
      return true
    }
    return false
  }

  getZoneColours(): ZoneColours {
    let fillColor: ZoneColour = ZoneColour.active
    let strokeColor: ZoneColour = ZoneColour.active
    let opacity: number

    if (this.status === EchoStatus.active) {
      // active zones
      fillColor = ZoneColour.active
      strokeColor = ZoneColour.active
      opacity = 0.55
    } else {
      // inactive zones
      fillColor = ZoneColour.inactive
      strokeColor = ZoneColour.inactive
      opacity = 0.55
    }
    if (this.selected) {
      // unselected zones should have darker fills
      fillColor = ZoneColour.selected
      strokeColor = ZoneColour.selected
      opacity = 0.55
    }
    if (this.model.hide_zone) {
      // completely hide the zone
      opacity = 0
    }
    return new ZoneColours(fillColor, strokeColor, opacity)
  }

  trigger(currentLocation: GeoJSON.Feature<GeoJSON.Point>, trigger: TriggerType, locationUpdateInterval: number): void {
    if (this.statusHasChanged) {
      this.statusHasChanged = false
    }

    // Let's play!
    // only set the gain to 1 if we're inside
    // - this is relevant for sync group items where we want them to play at 0 volume
    let gain: number = this.locStatus === EchoLocationStatus.inside ? 1.0 : 0.0
    // set spatialization volume
    if (trigger === TriggerType.location && this.model.shape === Shape.circle && this.spatialization) {
      gain = this.getGainFrom(currentLocation)
    }
    if (this.status !== EchoStatus.active) {
      this.statusHasChanged = true
      this.status = EchoStatus.active
      this.triggeredCount += 1
      if (!this.isPlaying()) {
        this.play(gain)
      }
    } else {
      this.setGain(gain, locationUpdateInterval)
    }
  }

  triggerWithBeacon(): void {
    if (this.statusHasChanged) {
      this.statusHasChanged = false
    }

    // Let's play!
    // only set the gain to 1 if we're inside
    // - this is relevant for sync group items where we want them to play at 0 volume
    const gain = this.locStatus === EchoLocationStatus.inside ? 1.0 : 0.0

    if (!this.isPlaying() && this.status === EchoStatus.inactive) {
      this.statusHasChanged = true
      this.status = EchoStatus.active
      this.triggeredCount++
      this.play(gain)
    }

  }

  getGainFrom(currentLocation: GeoJSON.Feature<GeoJSON.Point>): number {
    let gain = 1.0
    const currentDistance = distance(this.centreCoords, currentLocation) * 1000 // in km
    // we can potentially be outside a zone and it still play, so in this case we set gain to 0
    if (currentDistance > this.model.radius) {
      gain = 0.0
    } else {
      // make sure the gain is between 0 and 1
      gain = Math.max(0.0, Math.min(1.0, (1.0 - (currentDistance / this.model.radius))))
    }
    return gain
  }

  detrigger(): void {
    this.statusHasChanged = true
    if (this.status === EchoStatus.active) {
      this.status = EchoStatus.inactive
      this.pauseOrStop()
    }
  }

  play(gain: number): void {
    if (this.players.length === 0) {
      // looks like we're trying to play a non-loaded file
      this.loadPlayers()
    }
    this.players.forEach(async player => {
      // still must check for a non-nil player - we could be missing a file
      if (player.shouldPlay()) {
        this.setGain(gain)
        await player.play()
      }
    })
  }

  addProgressUpdateObserver(observer: () => Event): void {
    this.players.forEach(player => {
      if (player.playerElement) {
        player.playerElement.addEventListener('timeupdate', observer)
      }
    })
  }

  removeProgressObserver(): void {
    /* this.players.forEach(player => {
      // @TODO: save the observer somewhere
      // player.playerElement.removeEventListener('timeupdate')
    }) */
  }

  /**
   * Pause or stop the players
   * Should respect
   *
   * @param force Only if we manually stop
   */
  pauseOrStop(force = false): void {
    for (const player of this.players.filter(p => p.shouldStop(force))) {
      if (player.resume) {
        player.pause(force)
      } else {
        player.stop(force)
      }
    }
  }

  pause(force = false): void {
    for (const player of this.players.filter(p => p.shouldStop(force))) {
      player.pause(force)
    }
  }

  stop(force = false): void {
    for (const player of this.players.filter(p => p.shouldStop(force))) {
      player.stop(force)
    }
  }

  stopPlayers(fade = 0.5, force = false): void {
    for (const player of this.players.filter(p => p.shouldStop(force))) {
      player.stop(force, fade)
    }
  }

  setCurrentPosition(percentage: number): void {
    for (const player of this.players) {
      player.setCurrentPosition(percentage)
    }
  }

  setGain(gain: number, locationUpdateInterval?: number): void {
    for (const player of this.players) {
      player.setPlayerGain(gain, locationUpdateInterval)
    }
  }

  isPlaying(): boolean {
    return this.players.map(p => p.isPlaying()).reduce((prev, isPlaying) => prev || isPlaying, false)
  }

  getPlayingPlayers(): EchoesPlayer[] {
    return this.players.filter(p => p.isPlaying())
  }

  getProgressTime(): number {
    if (this.model.elements.length > 0) {
      return this.players[0].getProgressTime()
    } else {
      return 0.0
    }
  }

  getTotalTime(): number {
    if (this.model.elements.length > 0) {
      if (this.players.length === 0) {
        return 0.0
      }
      return this.players.map(p => p.getTotalTime()).reduce((prev, time) => prev + time, 0)
    } else {
      return 0.0
    }
  }

  getTotalTimePrintable(): string {
    const totalTime = this.getTotalTime()
    const seconds = Math.round(totalTime) % 60
    const minutes = Math.round(totalTime) / 60

    return `${minutes}:${seconds}`
  }

}
