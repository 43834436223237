import { Action, Reducer } from 'redux'
import {
  LOGIN_SUCCESS,
  LOGOUT,
  REQUEST_PASSWORD_RESET_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  SIGNUP_SUCCESS,
} from '../constants/auth.constants'

const initialState: IAuthState = {
  user: undefined,
  token: undefined,
  initialValues: {
    username: '',
    password: '',
  },
}

const auth: Reducer<IAuthState, Action<string>> = (state = initialState, action): IAuthState => {
  switch (action.type) {
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
      }
    }
    case LOGOUT: {
      return {
        ...state,
        token: undefined,
      }
    }
    case REQUEST_PASSWORD_RESET_SUCCESS:
      return state
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
      }
    default:
      return state
  }
}

export default auth
