import { applyMiddleware, createStore } from 'redux'
import { createMigrate, persistStore, persistReducer, PersistedState } from 'redux-persist'
import localstorage from 'redux-persist/lib/storage'
import localforage from 'localforage'
import rootReducer from '../reducers'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { composeWithDevTools } from 'redux-devtools-extension'

const resetState = () => {
  console.debug('Migration running')
  return {} as PersistedState
}

const migrations = {
  8: resetState,
  9: resetState,
}

const storage = process.env.NODE_ENV === 'production' ? localforage : localstorage

const persistConfig = {
  key: 'echoes-creator',
  version: 9,
  storage,
  blacklist: ['stats'],
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeWithDevTools({
    actionsBlacklist: [
      '@@redux-form/REGISTER_FIELD',
      '@@redux-form/UNREGISTER_FIELD',
    ],
    trace: true,
    traceLimit: 25,
  })(applyMiddleware(thunk, promise))
)
const persistor = persistStore(store)

export { store, persistor }
