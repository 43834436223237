/* SUBSCRIPTION */
export const GETTING_SUBSCRIPTIONS = 'GETTING_SUBSCRIPTIONS'
export const GOT_SUBSCRIPTIONS = 'GOT_SUBSCRIPTIONS'
export const GETTING_SUBSCRIPTION = 'GETTING_SUBSCRIPTION'
export const GOT_SUBSCRIPTION = 'GOT_SUBSCRIPTION'

export const SET_SUBSCRIPTION_TYPE = 'SET_SUBSCRIPTION_TYPE'


export const GETTING_MY_SUBSCRIPTIONS = 'GETTING_MY_SUBSCRIPTIONS'
export const GOT_MY_SUBSCRIPTIONS = 'GOT_MY_SUBSCRIPTIONS'
export const ERROR_GETTING_MY_SUBSCRIPTIONS = 'ERROR_GETTING_MY_SUBSCRIPTIONS'

export const CREATING_SUBSCRIPTION = 'CREATING_SUBSCRIPTION'
export const CREATED_SUBSCRIPTION = 'CREATED_SUBSCRIPTION'
export const ERROR_CREATING_SUBSCRIPTION = 'ERROR_CREATING_SUBSCRIPTION'
export const DISMISS_CREATING_SUBSCRIPTION_ERROR = 'DISMISS_CREATING_SUBSCRIPTION_ERROR'

export const CREATING_SUBSCRIPTION_CUSTOMER = 'CREATING_SUBSCRIPTION_CUSTOMER'
export const CREATED_SUBSCRIPTION_CUSTOMER = 'CREATED_SUBSCRIPTION_CUSTOMER'
export const ERROR_CREATING_SUBSCRIPTION_CUSTOMER = 'ERROR_CREATING_SUBSCRIPTION_CUSTOMER'
export const DISMISS_CREATING_SUBSCRIPTION_CUSTOMER_ERROR = 'DISMISS_CREATING_SUBSCRIPTION_CUSTOMER_ERROR'

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const DELETING_SUBSCRIPTION = 'DELETING_SUBSCRIPTION'
export const DELETING_SUBSCRIPTION_ERROR = 'DELETING_SUBSCRIPTION_ERROR'
export const DISMISS_DELETING_SUBSCRIPTION_ERROR = 'DISMISS_DELETING_SUBSCRIPTION_ERROR'
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION'
export const UPDATE_SUBSCRIPTION_LOADING_START = 'UPDATE_SUBSCRIPTION_LOADING_START'
export const UPDATE_SUBSCRIPTION_LOADING_END = 'UPDATE_SUBSCRIPTION_LOADING_END'
export const GETTING_SUBSCRIPTION_PRODUCTS = 'GETTING_SUBSCRIPTION_PRODUCTS'
export const GOT_SUBSCRIPTION_PRODUCTS = 'GOT_SUBSCRIPTION_PRODUCTS'
export const ERROR_GETTING_SUBSCRIPTION_PRODUCTS = 'ERROR_GETTING_SUBSCRIPTION_PRODUCTS'
export const DISMISS_ERROR_CREATING_STRIPE_CUSTOMER_PORTAL_LINK = 'DISMISS_ERROR_CREATING_STRIPE_CUSTOMER_PORTAL_LINK'
export const ERROR_CREATING_STRIPE_CUSTOMER_PORTAL_LINK = 'ERROR_CREATING_STRIPE_CUSTOMER_PORTAL_LINK'

export const GETTING_ADMIN_SUBSCRIPTIONS = 'GETTING_ADMIN_SUBSCRIPTIONS'
export const GOT_ADMIN_SUBSCRIPTIONS = 'GOT_ADMIN_SUBSCRIPTIONS'
export const ERROR_GETTING_ADMIN_SUBSCRIPTIONS = 'ERROR_GETTING_ADMIN_SUBSCRIPTIONS'
