import { Reducer } from 'redux'
import { GETTING_TREND_STATS, GOT_TREND_STATS, ERROR_GETTING_TREND_STATS } from '../constants/stats.constants'

const initialState: IStatsState = {
  gettingTrendStats: false,
  trendStats: [],
  gettingTrendStatsError: false,
  errorMessage: '',
  initialValues: {
    statQuery: {
      date_from: '-30d',
      interval: 'week',
      events: [
        {
          id: 'start_tour',
          name: 'start_tour',
          type: 'events',
          order: 0
        }
      ],
      properties: {
        "type": "AND",
        "values": [
          {
            "type": "AND",
            "values": [{
              "key": "item_id",
              "value": ["62d5df26a2f9077dd825afc8"],
              "operator": "exact",
              "type": "event"
            }]
          }]
      },
    },
    fromDate: new Date(),
    toDate: new Date(),
    dateFromType: '-7D',
    pub_status: '',
  }
}

const stats: Reducer<IStatsState> = (state = initialState, action) => {
  switch (action.type) {
    case GETTING_TREND_STATS:
      return {
        ...state,
        gettingTrendStats: true,
        gettingTrendStatsError: false
      }
    case GOT_TREND_STATS:
      return {
        ...state,
        gettingTrendStats: false,
        trendStats: action.payload === '' ? initialState.trendStats : action.payload,
        gettingTrendStatsError: false
      }
    case ERROR_GETTING_TREND_STATS:
      return {
        ...state,
        gettingTrendStats: false,
        gettingTrendStatsError: true,
        errorMessage: action.payload
      }
    default:
      return state
  }
}

export default stats
