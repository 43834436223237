export enum PubStatus {
  public = 'public',
  private = 'private',
  draft = 'draft',
}

export enum CollectionStatus {
  normal,
  downloading,
  downloaded,
}

export type VoiceGroupAction = 'mute' | 'duck' | 'pause'
