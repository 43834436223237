import { Reducer } from 'redux'
import {
  SET_COLLECTION_PARAMS,
  GETTING_MY_COLLECTIONS,
  GOT_MY_COLLECTIONS,
  ERROR_GETTING_MY_COLLECTIONS,
  GETTING_ADMIN_COLLECTIONS,
  GOT_ADMIN_COLLECTIONS,
  ERROR_GETTING_ADMIN_COLLECTIONS,
  GETTING_STATS_COLLECTIONS,
  GOT_STATS_COLLECTIONS,
  ERROR_GETTING_STATS_COLLECTIONS,
  GOT_COLLECTION,
  CREATED_COLLECTION,
  UPDATE_COLLECTION,
  DELETE_COLLECTION,
  SELECT_ECHO,
  DESELECT_ECHO,
  SELECT_LINE,
  DESELECT_LINE,
  SET_SIMULATION_POSITION,
  OPEN_DELETE_ECHO_MODAL,
  CLOSE_DELETE_ECHO_MODAL,
  CREATING_COLLECTION,
  ERROR_CREATING_COLLECTION,
  UPDATING_COLLECTION,
  ERROR_UPDATING_COLLECTION,
  GETTING_COLLECTION,
  ERROR_GETTING_COLLECTION,
  RESET_COLLECTION_MODALS,
  SET_COLLECTION_EDIT_TAB,
  SET_IMPORTED_GPX_KML_GEOJSON,
  NEW_COLLECTION,
  SET_ECHO_FILTER_TEXT,
  START_SIMULATING,
  STOP_SIMULATING,
} from '../constants/walk.constants'
import { PubStatus } from '../core/collection'
import { CollectionEditTab, SimulationType } from '../actions/walk.actions'

const initialState: IWalkState = {
  params: {
    limit: 100,
    skip: 0,
  },
  count: 0,
  gotMyCollections: false,
  gettingMyCollections: false,
  gettingMyCollectionsError: false,
  myCollections: [],
  gotAdminCollections: false,
  gettingAdminCollections: false,
  gettingAdminCollectionsError: false,
  adminCollections: [],
  gotStatsCollections: false,
  gettingStatsCollections: false,
  gettingStatsCollectionsError: false,
  statsCollections: [],
  creatingCollection: false,
  updatingCollection: false,
  gettingCollection: false,
  selectedEcho: -1,
  selectedTrajectory: -1,
  deleteEchoModalOpen: false,
  echoToDelete: -1,
  selectedCollectionForDeletion: '',
  collectionEditTab: CollectionEditTab.echoes,
  gettingCollectionError: false,
  creatingCollectionError: false,
  errorMessage: '',
  isSimulating: false,
  simulationType: SimulationType.line,
  simulationPosition: [0, 0],
  simulationHeading: 0,
  echoFilterText: '',
  importedGpxKmlGeoJson: undefined,
  initialValues: {
    _id: null,
    title: [],
    description: [],
    pub_status: PubStatus.draft,
    channels: [],
    tags: [],
    categories: [],
    media: [],
    loc: {
      coordinates: [0, 1],
      type: 'Point',
    },
    echoes: [],
    profiles: [],
    privileges: [],
    onboarding: [],
    trajectories: [],
    place: [],
    sync_groups: [],
    voice_groups: [],
    timesync_groups: [],
    disable_subscriptions: false
  },
}

const walk: Reducer<IWalkState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GETTING_MY_COLLECTIONS:
      return {
        ...state,
        gotMyCollections: false,
        gettingMyCollections: true,
        gettingMyCollectionsError: false,
      }
    case GOT_MY_COLLECTIONS: {
      return {
        ...state,
        myCollections: action.payload.collections,
        count: action.payload.count,
        gotMyCollections: true,
        gettingMyCollections: false,
        gettingMyCollectionsError: false,
      }
    }
    case ERROR_GETTING_MY_COLLECTIONS:
      return {
        ...state,
        gotMyCollections: false,
        gettingMyCollections: false,
        gettingMyCollectionsError: true,
        errorMessage: action.payload,
      }
    case GETTING_ADMIN_COLLECTIONS:
      return {
        ...state,
        adminCollections: [],
        gotAdminCollections: false,
        gettingAdminCollections: true,
        gettingAdminCollectionsError: false,
      }
    case GOT_ADMIN_COLLECTIONS: {
      return {
        ...state,
        adminCollections: action.payload.collections,
        count: action.payload.count,
        gotAdminCollections: true,
        gettingAdminCollections: false,
        gettingAdminCollectionsError: false,
      }
    }
    case ERROR_GETTING_ADMIN_COLLECTIONS:
      return {
        ...state,
        gotAdminCollections: false,
        gettingAdminCollections: false,
        gettingAdminCollectionsError: true,
        errorMessage: action.payload.message,
      }
    case GETTING_STATS_COLLECTIONS:
      return {
        ...state,
        gotStatsCollections: false,
        gettingStatsCollections: true,
        gettingStatsCollectionsError: false,
      }
    case GOT_STATS_COLLECTIONS: {
      return {
        ...state,
        statsCollections: action.payload.collections,
        count: action.payload.count,
        gotStatsCollections: true,
        gettingStatsCollections: false,
        gettingStatsCollectionsError: false,
      }
    }
    case ERROR_GETTING_STATS_COLLECTIONS:
      return {
        ...state,
        gotStatsCollections: false,
        gettingStatsCollections: false,
        gettingStatsCollectionsError: true,
        errorMessage: action.payload.message,
      }
    case SET_COLLECTION_PARAMS:
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload,
        },
      }
    case NEW_COLLECTION:
      return {
        ...state,
        initialValues: initialState.initialValues
      }
    case GETTING_COLLECTION:
      return {
        ...state,
        gettingCollection: true,
        // initialValues: initialState.initialValues,
        gettingCollectionError: false,
      }
    case GOT_COLLECTION:
      return {
        ...state,
        gettingCollection: false,
        gettingCollectionError: false,
      }
    case ERROR_GETTING_COLLECTION:
      return {
        ...state,
        gettingCollection: false,
        // initialValues: initialState.initialValues,
        gettingCollectionError: true,
        errorMessage: action.payload,
      }
    case CREATING_COLLECTION:
      return {
        ...state,
        creatingCollectionError: false,
        creatingCollection: true,
        // initialValues: initialState.initialValues,
      }
    case CREATED_COLLECTION: {
      return {
        ...state,
        creatingCollectionError: false,
        creatingCollection: false,
        initialValues: action.payload.collection,
      }
    }
    case ERROR_CREATING_COLLECTION:
      return {
        ...state,
        creatingCollectionError: false,
        errorMessage: action.payload,
        creatingCollection: false,
      }
    case UPDATING_COLLECTION:
      return {
        ...state,
        updatingCollectionError: false,
        updatingCollection: true,
      }
    case UPDATE_COLLECTION: {
      return {
        ...state,
        updatingCollectionError: false,
        updatingCollection: false,
      }
    }
    case ERROR_UPDATING_COLLECTION:
      return {
        ...state,
        updatingCollection: false,
        updatingCollectionError: true,
        errorMessage: action.payload,
      }
    case DELETE_COLLECTION: {
      return {
        ...state,
      }
    }
    case SELECT_ECHO:
      return {
        ...state,
        selectedEcho: action.payload,
      }
    case DESELECT_ECHO:
      return {
        ...state,
        selectedEcho: -1,
      }
    case SELECT_LINE:
      return {
        ...state,
        selectedTrajectory: action.payload,
      }
    case DESELECT_LINE:
      return {
        ...state,
        selectedTrajectory: -1,
      }
    case SET_IMPORTED_GPX_KML_GEOJSON:
      return {
        ...state,
        importedGpxKmlGeoJson: action.payload
      }
    case START_SIMULATING:
      return {
        ...state,
        isSimulating: true,
        simulationType: action.payload
      }
    case STOP_SIMULATING:
      return {
        ...state,
        isSimulating: false
      }
    case SET_SIMULATION_POSITION: {
      const position = action.payload as IEchoesPosition
      return {
        ...state,
        simulationPosition: [position.coords[0], position.coords[1]],
        simulationHeading: position.heading
      }
    }
    case OPEN_DELETE_ECHO_MODAL:
      return {
        ...state,
        deleteEchoModalOpen: true,
        echoToDelete: action.payload,
      }
    case CLOSE_DELETE_ECHO_MODAL:
      return {
        ...state,
        deleteEchoModalOpen: false,
        echoToDelete: -1,
      }
    case SET_COLLECTION_EDIT_TAB:
      return {
        ...state,
        collectionEditTab: action.payload,
      }
    case SET_ECHO_FILTER_TEXT:
      return {
        ...state,
        echoFilterText: action.payload,
      }
    case RESET_COLLECTION_MODALS:
      return {
        ...state,
        deleteCollectionModalOpen: false,
        deleteEchoModalOpen: false,
        createCollectionModalOpen: false,
      }
    default:
      return state
  }
}

export default walk
