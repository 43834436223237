import React from 'react'
import Spinner from '../components/Spinner'

const Loading: React.FC = () => (
    <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100 loading-overlay">
      <Spinner className="display-3" active />
    </div>
  )

export default Loading
