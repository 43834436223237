import { Reducer } from 'redux'
import {
  GOT_SUBSCRIPTIONS,
  GOT_MY_SUBSCRIPTIONS,
  GOT_SUBSCRIPTION,
  SET_SUBSCRIPTION_TYPE,
  CREATED_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  GOT_SUBSCRIPTION_PRODUCTS,
  GETTING_SUBSCRIPTION_PRODUCTS,
  GETTING_SUBSCRIPTIONS,
  GETTING_SUBSCRIPTION,
  DELETING_SUBSCRIPTION,
  DELETING_SUBSCRIPTION_ERROR,
  DISMISS_DELETING_SUBSCRIPTION_ERROR,
  ERROR_GETTING_SUBSCRIPTION_PRODUCTS,
  CREATING_SUBSCRIPTION,
  ERROR_CREATING_SUBSCRIPTION,
  GETTING_MY_SUBSCRIPTIONS,
  GOT_ADMIN_SUBSCRIPTIONS,
  GETTING_ADMIN_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION_LOADING_START,
  UPDATE_SUBSCRIPTION_LOADING_END,
  ERROR_GETTING_ADMIN_SUBSCRIPTIONS,
  ERROR_GETTING_MY_SUBSCRIPTIONS,
  DISMISS_CREATING_SUBSCRIPTION_ERROR,
  CREATING_SUBSCRIPTION_CUSTOMER,
  CREATED_SUBSCRIPTION_CUSTOMER,
  ERROR_CREATING_SUBSCRIPTION_CUSTOMER,
  DISMISS_CREATING_SUBSCRIPTION_CUSTOMER_ERROR,
  ERROR_CREATING_STRIPE_CUSTOMER_PORTAL_LINK,
  DISMISS_ERROR_CREATING_STRIPE_CUSTOMER_PORTAL_LINK,
} from '../constants/subscription.constants'
import { SubscriptionType } from '../core/subscription'

const initialState: ISubscriptionState = {
  params: {
    limit: 10,
    skip: 0,
  },
  gotAdminSubscriptions: false,
  gettingAdminSubscriptions: false,
  gettingAdminSubscriptionsError: false,
  adminSubscriptions: [],
  count: 0,
  gettingSubscriptions: false,
  subscriptions: [],
  currentSubscriptionType: SubscriptionType.none,
  gotMySubscriptions: false,
  gettingMySubscriptions: false,
  gettingMySubscriptionsError: false,
  gettingSubscription: false,
  deletingSubscription: false,
  products: [],
  gettingSubscriptionProducts: false,
  gettingSubscriptionProductsError: false,
  updateSubscriptionLoading: false,
  creatingSubscription: false,
  creatingSubscriptionError: false,
  creatingSubscriptionCustomer: false,
  creatingSubscriptionCustomerError: false,
  errorMessage: '',
  deleteSubscriptionError: false,
  deleteSubscriptionErrorText: '',
  creatingStripeCustomerPortalLinkError: false,
  initialValues: {
    cardComplete: false,
  },
}

const subscription: Reducer<ISubscriptionState> = (state = initialState, action) => {
  switch (action.type) {
    case GETTING_SUBSCRIPTIONS: {
      return {
        ...state,
        gettingSubscriptions: true,
      }
    }
    case GOT_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: action.payload.subscriptions,
        gettingSubscriptions: false,
      }
    }
    case GETTING_SUBSCRIPTION: {
      return {
        ...state,
        gettingSubscription: true,
      }
    }
    case GETTING_MY_SUBSCRIPTIONS:
      return {
        ...state,
        gotMySubscriptions: false,
        gettingMySubscriptions: true,
        subscriptions: [],
      }
    case ERROR_GETTING_MY_SUBSCRIPTIONS: {
      return {
        ...state,
        gettingMySubscriptionsError: true,
        gotMySubscriptions: false,
        errorMessage: action.payload,
        gettingMySubscriptions: false,
      }
    }
    case GOT_MY_SUBSCRIPTIONS: {
      return {
        ...state,
        subscriptions: action.payload.subscriptions,
        gettingSubscription: false,
        gotMySubscriptions: true,
        gettingMySubscriptionsError: false,
      }
    }
    case GOT_SUBSCRIPTION: {
      return {
        ...state,
        gettingSubscription: false,
        subscription: action.payload.subscription,
      }
    }
    case SET_SUBSCRIPTION_TYPE: {
      return {
        ...state,
        currentSubscriptionType: action.payload
      }
    }
    case GETTING_ADMIN_SUBSCRIPTIONS:
      return {
        ...state,
        gotAdminSubscriptions: false,
        gettingAdminSubscriptions: true,
        gettingAdminSubscriptionsError: false,
      }
    case ERROR_GETTING_ADMIN_SUBSCRIPTIONS:
      return {
        ...state,
        gotAdminSubscriptions: false,
        gettingAdminSubscriptions: false,
        gettingAdminSubscriptionsError: true,
        errorMessage: action.payload.message,
      }
    case GOT_ADMIN_SUBSCRIPTIONS: {
      return {
        ...state,
        gotAdminSubscriptions: true,
        gettingAdminSubscriptions: false,
        gettingAdminSubscriptionsError: false,
        adminSubscriptions: action.payload.subscriptions,
        count: action.payload.count,
      }
    }
    case CREATING_SUBSCRIPTION: {
      return {
        ...state,
        creatingSubscription: true,
        errorMessage: '',
        creatingSubscriptionError: false,
      }
    }
    case CREATED_SUBSCRIPTION: {
      return {
        ...state,
        subscription: action.payload.subscription,
        creatingSubscription: false,
        creatingSubscriptionError: false,
      }
    }
    case ERROR_CREATING_SUBSCRIPTION: {
      return {
        ...state,
        creatingSubscriptionError: true,
        errorMessage: action.payload,
        creatingSubscription: false,
      }
    }
    case DISMISS_CREATING_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        creatingSubscriptionError: false,
        errorMessage: '',
      }
    }
    case CREATING_SUBSCRIPTION_CUSTOMER: {
      return {
        ...state,
        creatingSubscriptionCustomer: true,
        errorMessage: '',
        creatingSubscriptionCustomerError: false,
      }
    }
    case CREATED_SUBSCRIPTION_CUSTOMER: {
      return {
        ...state,
        stripeCustomerId: action.payload.stripe_customer_id,
        creatingSubscriptionCustomer: false,
        creatingSubscriptionCustomerError: false,
      }
    }
    case ERROR_CREATING_SUBSCRIPTION_CUSTOMER: {
      return {
        ...state,
        creatingSubscriptionCustomerError: true,
        errorMessage: action.payload,
        creatingSubscriptionCustomer: false,
      }
    }
    case DISMISS_CREATING_SUBSCRIPTION_CUSTOMER_ERROR: {
      return {
        ...state,
        creatingSubscriptionCustomerError: false,
        errorMessage: '',
      }
    }
    case UPDATE_SUBSCRIPTION_LOADING_START: {
      return {
        ...state,
        updateSubscriptionLoading: true,
      }
    }
    case UPDATE_SUBSCRIPTION_LOADING_END: {
      return {
        ...state,
        updateSubscriptionLoading: false,
      }
    }
    case UPDATE_SUBSCRIPTION: {
      return {
        ...state,
        updateSubscriptionLoading: false,
        subscription: action.payload.subscription,
        subscriptions: state.subscriptions.map((subscription) => {
          if (subscription._id === action.payload.subscription._id) {
            return { ...subscription, ...action.payload.subscription }
          }

          return subscription
        }),
      }
    }
    case DELETING_SUBSCRIPTION: {
      return {
        ...state,
        deletingSubscription: true,
      }
    }
    case DELETING_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        deletingSubscription: false,
        deleteSubscriptionError: true,
        deleteSubscriptionErrorText: action.payload
      }
    }
    case DISMISS_DELETING_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        deleteSubscriptionError: false,
        deleteSubscriptionErrorText: ''
      }
    }
    case DELETE_SUBSCRIPTION: {
      return {
        ...state,
        deletingSubscription: false,
      }
    }
    case GETTING_SUBSCRIPTION_PRODUCTS: {
      return {
        ...state,
        gettingSubscriptionProducts: true,
        gettingSubscriptionProductsError: false,
      }
    }
    case GOT_SUBSCRIPTION_PRODUCTS: {
      return {
        ...state,
        products: action.payload,
        gettingSubscriptionProducts: false,
        gettingSubscriptionProductsError: false,
      }
    }
    case ERROR_GETTING_SUBSCRIPTION_PRODUCTS: {
      return {
        ...state,
        products: [],
        gettingSubscriptionProducts: false,
        gettingSubscriptionProductsError: true,
        errorMessage: action.payload.message,
      }
    }
    case ERROR_CREATING_STRIPE_CUSTOMER_PORTAL_LINK: {
      return {
        ...state,
        creatingStripeCustomerPortalLinkError: true,
        errorMessage: action.payload.message
      }
    }
    case DISMISS_ERROR_CREATING_STRIPE_CUSTOMER_PORTAL_LINK: {
      return {
        ...state,
        creatingStripeCustomerPortalLinkError: false,
        errorMessage: ''
      }
    }
    default:
      return state
  }
}

export default subscription
