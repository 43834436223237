import { Dispatch } from 'redux'
import { ERROR_GETTING_APP_CONFIG, GETTING_APP_CONFIG, GOT_APP_CONFIG, RESET_APP_CONFIG } from '../constants/appConfig.constants'
import appConfigApi from '../api/modules/appConfig.api'

export async function getAppConfig(dispatch: Dispatch): Promise<void> {
    dispatch({ type: GETTING_APP_CONFIG })
    try {
        const res = await appConfigApi.get()
        dispatch({ type: GOT_APP_CONFIG, payload: res.data })
    } catch (err) {
        dispatch({ type: ERROR_GETTING_APP_CONFIG })
    }
}

export const resetAppConfig = (dispatch: Dispatch) => {
    dispatch({ type: RESET_APP_CONFIG })
}
