import { Reducer } from 'redux'
import {
  GOT_PROFILES,
  GOT_PROFILE,
  NEW_PROFILE,
  CREATE_PROFILE,
  UPDATE_PROFILE,
  DELETE_PROFILE,
  GETTING_PROFILES,
  GETTING_PROFILE,
  ERROR_GETTING_PROFILES,
  ERROR_GETTING_PROFILE,
  CREATING_PROFILE,
  ERROR_CREATING_PROFILE,
  UPDATING_PROFILE,
  ERROR_UPDATING_PROFILE,
  DELETING_PROFILE,
  ERROR_DELETING_PROFILE,
  DISMISS_PROFILE_ERROR,
  OPEN_ACTIVATE_PROFILE_MODAL,
  CLOSE_ACTIVATE_PROFILE_MODAL,
  GETTING_COLLECTIONS_FOR_PROFILE,
  GOT_COLLECTIONS_FOR_PROFILE,
  ERROR_GETTING_COLLECTIONS_FOR_PROFILE,
  GETTING_ADMIN_PROFILES,
  GOT_ADMIN_PROFILES,
  ERROR_GETTING_ADMIN_PROFILES
} from '../constants/profile.constants'
import { PubStatus } from '../core/collection'

export class ProfileModel {
  _id = undefined
  name = ''
  slug = ''
  description = [{
    lang: 'en',
    text: ''
  }]
  pub_status = PubStatus.draft
  active = false
  media = []
  creator = null
  privileges = []
}

const initialState: IProfileState = {
  profiles: [],
  count: 0,
  adminProfiles: [],
  adminProfilesCount: 0,
  profile: undefined,
  gettingProfiles: false,
  gettingProfile: false,
  creatingProfile: false,
  updatingProfile: false,
  deletingProfile: false,
  gettingCollectionsForProfile: false,
  gotCollectionsForProfile: false,
  gettingCollectionsForProfileError: false,
  profileCollections: [],
  gettingProfilesError: false,
  gettingProfileError: false,
  creatingProfileError: false,
  updatingProfileError: false,
  deletingProfileError: false,
  activateProfileModalOpen: false,
  selectedProfile: undefined,
  errorMessage: '',
  initialValues: new ProfileModel(),
}

const profile: Reducer<IProfileState> = (state = initialState, action) => {
  switch (action.type) {
    case GETTING_PROFILES: {
      return {
        ...state,
        profiles: [],
        gettingProfiles: true,
        gettingProfilesError: false,
      }
    }
    case GOT_PROFILES: {
      return {
        ...state,
        profiles: action.payload.profiles,
        count: action.payload.count,
        gettingProfiles: false,
        gettingProfilesError: false,
      }
    }
    case ERROR_GETTING_PROFILES:
      return {
        ...state,
        gettingProfiles: false,
        gettingProfilesError: true,
        errorMessage: action.payload,
      }
    case GETTING_ADMIN_PROFILES: {
      return {
        ...state,
        adminProfiles: [],
        adminProfilesCount: 0,
        gettingAdminProfiles: true,
        gettingAdminProfilesError: false,
      }
    }
    case GOT_ADMIN_PROFILES: {
      return {
        ...state,
        adminProfiles: action.payload.profiles,
        adminProfilesCount: action.payload.count,
        gettingAdminProfiles: false,
        gettingAdminProfilesError: false,
      }
    }
    case ERROR_GETTING_ADMIN_PROFILES:
      return {
        ...state,
        gettingAdminProfiles: false,
        gettingAdminProfilesError: true,
        errorMessage: action.payload,
      }
    case GETTING_PROFILE: {
      return {
        ...state,
        gettingProfile: true,
        gettingProfileError: false,
      }
    }
    case GOT_PROFILE: {
      return {
        ...state,
        profile: action.payload.profile,
        gettingProfile: false,
        gettingProfileError: false,
      }
    }
    case ERROR_GETTING_PROFILE:
      return {
        ...state,
        gettingProfile: false,
        profile: initialState.initialValues,
        gettingProfileError: true,
        errorMessage: action.payload,
      }
    case GETTING_COLLECTIONS_FOR_PROFILE:
      return {
        ...state,
        gotCollectionsForProfile: false,
        gettingCollectionsForProfile: true,
        gettingCollectionsForProfileError: false,
        profileCollections: []
      }
    case GOT_COLLECTIONS_FOR_PROFILE:
      return {
        ...state,
        gotCollectionsForProfile: true,
        gettingCollectionsForProfile: false,
        gettingCollectionsForProfileError: false,
        profileCollections: action.payload
      }
    case ERROR_GETTING_COLLECTIONS_FOR_PROFILE:
      return {
        ...state,
        gotCollectionsForProfile: false,
        gettingCollectionsForProfile: false,
        gettingCollectionsForProfileError: true,
        errorMessage: action.payload
      }
    case NEW_PROFILE: {
      return {
        ...state,
        profile: { ...initialState.initialValues, ...action.payload },
      }
    }
    case CREATING_PROFILE:
      return {
        ...state,
        creatingProfile: true,
        creatingProfileError: false,
      }
    case CREATE_PROFILE: {
      return {
        ...state,
        creatingProfile: false,
        creatingProfileError: false,
      }
    }
    case ERROR_CREATING_PROFILE:
      return {
        ...state,
        creatingProfile: false,
        creatingProfileError: true,
        errorMessage: action.payload,
      }
    case UPDATING_PROFILE:
      return {
        ...state,
        updatingProfile: true,
        updatingProfileError: false,
      }
    case UPDATE_PROFILE: {
      return {
        ...state,
        updatingProfile: false,
        profile: action.payload.profile,
        updatingProfileError: false,
      }
    }
    case ERROR_UPDATING_PROFILE:
      return {
        ...state,
        updatingProfile: false,
        updatingProfileError: false,
        errorMessage: action.payload,
      }
    case DELETING_PROFILE:
      return {
        ...state,
        deletingProfileError: false,
        deletingProfile: true,
      }
    case DELETE_PROFILE: {
      return {
        ...state,
        deletingProfile: false,
        deletingProfileError: false,
      }
    }
    case ERROR_DELETING_PROFILE:
      return {
        ...state,
        deletingProfile: false,
        deletingProfileError: true,
        errorMessage: action.payload,
      }
    case OPEN_ACTIVATE_PROFILE_MODAL:
      return {
        ...state,
        activateProfileModalOpen: true,
        selectedProfile: action.payload,
      }
    case CLOSE_ACTIVATE_PROFILE_MODAL:
      return {
        ...state,
        activateProfileModalOpen: false,
        selectedProfile: undefined,
      }
    case DISMISS_PROFILE_ERROR:
      return {
        ...state,
        gettingProfilesError: false,
        gettingProfileError: false,
        creatingProfileError: false,
        updatingProfileError: false,
        deletingProfileError: false
      }
    default:
      return state
  }
}

export default profile
