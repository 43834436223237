/* USER */
export const SET_USER_PARAMS = 'SET_USER_PARAMS'
export const GOT_SELF = 'GOT_SELF'
export const GETTING_USERS = 'GETTING_USERS'
export const GOT_USERS = 'GOT_USERS'
export const ERROR_GETTING_USERS = 'ERROR_GETTING_USERS'
export const GETTING_USER = 'GETTING_USER'
export const GOT_USER = 'GOT_USER'
export const ERROR_GETTING_USER = 'ERROR_GETTING_USER'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_SELF = 'UPDATE_SELF'
export const CHANGE_LANG = 'CHANGE_LANG'
