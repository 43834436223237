import collectionAPI from '../api/modules/collection.api'
import { Dispatch } from 'redux'
import {
  GETTING_MY_COLLECTIONS,
  GOT_MY_COLLECTIONS,
  ERROR_GETTING_MY_COLLECTIONS,
  GETTING_ADMIN_COLLECTIONS,
  GOT_ADMIN_COLLECTIONS,
  ERROR_GETTING_ADMIN_COLLECTIONS,
  GETTING_STATS_COLLECTIONS,
  GOT_STATS_COLLECTIONS,
  ERROR_GETTING_STATS_COLLECTIONS,
  GOT_COLLECTION,
  CREATED_COLLECTION,
  UPDATING_COLLECTION,
  UPDATE_COLLECTION,
  ERROR_UPDATING_COLLECTION,
  DELETE_COLLECTION,
  SELECT_ECHO,
  DESELECT_ECHO,
  OPEN_DELETE_ECHO_MODAL,
  CLOSE_DELETE_ECHO_MODAL,
  CREATING_COLLECTION,
  ERROR_CREATING_COLLECTION,
  SET_COLLECTION_PARAMS,
  GETTING_COLLECTION,
  SET_SIMULATION_POSITION,
  RESET_COLLECTION_MODALS,
  SET_IMPORTED_GPX_KML_GEOJSON,
  NEW_COLLECTION,
  SET_ECHO_FILTER_TEXT,
} from '../constants/walk.constants'

export enum SimulationType {
  gps,
  line,
  tap
}

export enum CollectionEditTab {
  collection = 'collection',
  echoes = 'echoes',
  trajectories = 'trajectories',
  simulator = 'simulator',
}

export async function setParam(dispatch: Dispatch, params: IWalkQueryParams) {
  dispatch({ type: SET_COLLECTION_PARAMS, payload: params })
}
export async function getAdminCollections(dispatch: Dispatch, params: IWalkQueryParams) {
  dispatch({ type: GETTING_ADMIN_COLLECTIONS })
  try {
    const response = await collectionAPI.getAll(params)
    dispatch({
      type: GOT_ADMIN_COLLECTIONS,
      payload: {
        collections: response.data,
        count: Number(response.headers['x-total-count']),
      },
    })
  } catch (err) {
    dispatch({ type: ERROR_GETTING_ADMIN_COLLECTIONS, payload: err })
  }
}
export async function getStatsCollections(
  dispatch: Dispatch,
  params: IWalkQueryParams
): Promise<ICollectionModel[]> {
  dispatch({ type: GETTING_STATS_COLLECTIONS })
  try {
    const response = await collectionAPI.getMine(params)

    dispatch({
      type: GOT_STATS_COLLECTIONS,
      payload: {
        collections: response.data,
        count: Number(response.headers['x-total-count']),
      },
    })

    return response.data as ICollectionModel[]
  } catch (err) {
    dispatch({ type: ERROR_GETTING_STATS_COLLECTIONS, payload: err })

    return []
  }
}

export async function getMyCollections(
  dispatch: Dispatch,
  params: IWalkQueryParams
): Promise<ICollectionModel[]> {
  dispatch({ type: GETTING_MY_COLLECTIONS })
  try {
    const response = await collectionAPI.getMine(params)
    dispatch({
      type: GOT_MY_COLLECTIONS,
      payload: {
        collections: response.data,
        count: Number(response.headers['x-total-count']),
      },
    })
    return response.data as ICollectionModel[]
  } catch (err) {
    dispatch({ type: ERROR_GETTING_ADMIN_COLLECTIONS, payload: err })
    return []
  }
}

export async function newCollection(dispatch: Dispatch) {
  dispatch({
    type: NEW_COLLECTION,
  })
}
export async function getCollection(dispatch: Dispatch, slug: string) {
  dispatch({
    type: GETTING_COLLECTION,
  })
  try {
    const response = await collectionAPI.get(slug)
    dispatch({
      type: GOT_COLLECTION,
      payload: { collection: response.data },
    })
    return response.data as ICollectionModel
  } catch (err) {
    const error = err as Error
    dispatch({ type: ERROR_GETTING_MY_COLLECTIONS, payload: error.message })
    return
  }
}

export async function createCollection(dispatch: Dispatch, collection: ICollectionModel | INewCollectionModel, duplicate = false): Promise<ICollectionModel | undefined> {
  dispatch({
    type: CREATING_COLLECTION,
  })
  try {
    const response = await collectionAPI.create(collection, duplicate)
    dispatch({
      type: CREATED_COLLECTION,
      payload: { collection: response.data },
    })
    return response.data
  } catch (err) {
    const error = err as Error
    dispatch({
      type: ERROR_CREATING_COLLECTION,
      payload: error.message,
    })
  }
  return undefined
}

export async function updateCollection(dispatch: Dispatch, collection: ICollectionModel) {
  dispatch({
    type: UPDATING_COLLECTION,
  })
  try {
    const response = await collectionAPI.update(collection)
    dispatch({
      type: UPDATE_COLLECTION,
      payload: { collection: response.data },
    })
    return response.data as ICollectionModel
  } catch (err) {
    const error = err as Error
    dispatch({
      type: ERROR_UPDATING_COLLECTION,
      payload: error.message,
    })
    throw err
  }
}
export async function deleteCollection(dispatch: Dispatch, slug: string) {
  await collectionAPI.delete(slug)
  dispatch({ type: DELETE_COLLECTION })
}
export async function selectEcho(dispatch: Dispatch, index: number) {
  dispatch({ type: SELECT_ECHO, payload: index })
}
export async function deselectEcho(dispatch: Dispatch) {
  dispatch({ type: DESELECT_ECHO })
}
export async function setSimulationPosition(dispatch: Dispatch, position: IEchoesPosition) {
  dispatch({ type: SET_SIMULATION_POSITION, payload: position })
}
export async function resetCollectionModals(dispatch: Dispatch) {
  dispatch({ type: RESET_COLLECTION_MODALS })
}
export async function openEchoDeleteModal(dispatch: Dispatch, i: number) {
  dispatch({
    type: OPEN_DELETE_ECHO_MODAL,
    payload: i,
  })
}
export async function closeEchoDeleteModal(dispatch: Dispatch) {
  dispatch({ type: CLOSE_DELETE_ECHO_MODAL })
}
export async function setImportedGpxKmlGeojson(dispatch: Dispatch, geoJson: Record<string, unknown>) {
  dispatch({ type: SET_IMPORTED_GPX_KML_GEOJSON, payload: geoJson })
}
export async function setEchoFilterText(dispatch: Dispatch, text: string) {
  dispatch({ type: SET_ECHO_FILTER_TEXT, payload: text })
}
