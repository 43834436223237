/* COLLECTION */
export const GETTING_MY_COLLECTIONS = 'GETTING_MY_COLLECTIONS'
export const GOT_MY_COLLECTIONS = 'GOT_MY_COLLECTIONS'
export const ERROR_GETTING_MY_COLLECTIONS = 'ERROR_GETTING_MY_COLLECTIONS'

export const GETTING_ADMIN_COLLECTIONS = 'GETTING_ADMIN_COLLECTIONS'
export const GOT_ADMIN_COLLECTIONS = 'GOT_ADMIN_COLLECTIONS'
export const ERROR_GETTING_ADMIN_COLLECTIONS = 'ERROR_GETTING_ADMIN_COLLECTIONS'

export const GETTING_STATS_COLLECTIONS = 'GETTING_STATS_COLLECTIONS'
export const GOT_STATS_COLLECTIONS = 'GOT_STATS_COLLECTIONS'
export const ERROR_GETTING_STATS_COLLECTIONS = 'ERROR_GETTING_STATS_COLLECTIONS'

export const SET_COLLECTION_PARAMS = 'SET_COLLECTION_PARAMS'

export const NEW_COLLECTION = 'NEW_COLLECTION'
export const GETTING_COLLECTION = 'GETTING_COLLECTION'
export const GOT_COLLECTION = 'GOT_COLLECTION'
export const ERROR_GETTING_COLLECTION = 'ERROR_GETTING_COLLECTION'

export const CREATING_COLLECTION = 'CREATING_COLLECTION'
export const CREATED_COLLECTION = 'CREATED_COLLECTION'
export const ERROR_CREATING_COLLECTION = 'ERROR_CREATING_COLLECTION'

export const UPDATING_COLLECTION = 'UPDATING_COLLECTION'
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION'
export const ERROR_UPDATING_COLLECTION = 'ERROR_UPDATING_COLLECTION'

export const DELETE_COLLECTION = 'DELETE_COLLECTION'

export const SELECT_ECHO = 'SELECT_ECHO'
export const DESELECT_ECHO = 'DESELECT_ECHO'

export const SELECT_LINE = 'SELECT_LINE'
export const DESELECT_LINE = 'DESELECT_LINE'

export const SET_SIMULATION_SPEED = 'SET_SIMULATION_SPEED'
export const SET_TOTAL_DISTANCE = 'SET_TOTAL_DISTANCE'
export const SET_DISTANCE_SIMULATED = 'SET_DISTANCE_SIMULATED'
export const SET_SIMULATION_POSITION = 'SET_SIMULATION_POSITION'
export const START_SIMULATING = 'START_SIMULATING'
export const STOP_SIMULATING = 'STOP_SIMULATING'

export const OPEN_DELETE_ECHO_MODAL = 'OPEN_DELETE_ECHO_MODAL'
export const CLOSE_DELETE_ECHO_MODAL = 'CLOSE_DELETE_ECHO_MODAL'

export const SET_IMPORTED_GPX_KML_GEOJSON = 'SET_IMPORTED_GPX_KML_GEOJSON'

export const SET_COLLECTION_EDIT_TAB = 'SET_COLLECTION_EDIT_TAB'

export const SET_ECHO_FILTER_TEXT = 'SET_ECHO_FILTER_TEXT'

export const RESET_COLLECTION_MODALS = 'RESET_COLLECTION_MODALS'
