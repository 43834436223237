import axios from 'axios'
import { request, response } from './interceptors'

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URI,
  timeout: process.env.NODE_ENV === 'production' ? 30000 : 0,
})

const uploadInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URI,
  timeout: 0,
})

const convertInstance = axios.create({
  baseURL: import.meta.env.VITE_CONVERT_BASE_URI,
  timeout: 0,
})

instance.interceptors.request.use(request.success, request.failure)

instance.interceptors.response.use(response.success, response.failure)

uploadInstance.interceptors.request.use(request.success, request.failure)

uploadInstance.interceptors.response.use(response.success, response.failure)

export { axios, instance, uploadInstance, convertInstance }
