import { Reducer, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import auth from './auth.reducers'
import map from './map.reducers'
import walk from './walk.reducers'
import user from './user.reducers'
import profile from './profile.reducers'
import media from './media.reducers'
import subscription from './subscription.reducers'
import userProfile from './userProfile.reducers'
import stats from './stats.reducers'
import appConfig from './appConfig.reducers'

// @ts-ignore
const rootReducer = combineReducers({
  auth,
  map,
  walk,
  user,
  profile,
  media,
  subscription,
  userProfile,
  stats,
  appConfig,
  form: formReducer as Reducer,
})

export default rootReducer
