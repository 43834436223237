import React, { ReactNode, Suspense, useEffect, useMemo, useState } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Toast, ToastBody, ToastHeader } from 'reactstrap'
import { subDays } from 'date-fns'
import Loading from '../components/Loading'
import { getAppConfig } from '../actions/appConfig.actions'

const Login = React.lazy(() => import(/* webpackChunkName:'Login' */ '../components/auth/Login'))
const Walks = React.lazy(() => import(/* webpackChunkName:'Walks' */ '../components/Walks'))
const Signup = React.lazy(() => import(/* webpackChunkName:'Signup' */ '../components/auth/Signup'))
const RequestReset = React.lazy(() => import(/* webpackChunkName:'RequestReset' */ '../components/auth/RequestReset'))
const ResetPassword = React.lazy(() => import(/* webpackChunkName:'ResetPassword' */ '../components/auth/ResetPassword'))
const Walk = React.lazy(() => import(/* webpackChunkName:'Walk' */ '../components/Walk'))
const Stats = React.lazy(() => import(/* webpackChunkName:'Stats' */ '../components/Stats'))
const WalkStats = React.lazy(() => import(/* webpackChunkName:'WalkStats' */ '../components/WalkStats'))
const Account = React.lazy(() => import(/* webpackChunkName:'Account' */ '../components/Account'))
const EditProfile = React.lazy(() => import(/* webpackChunkName:'EditProfile' */ '../components/EditProfile'))
const Profiles = React.lazy(() => import(/* webpackChunkName:'Profiles' */ '../components/Profiles'))
const PurchaseSubscription = React.lazy(() => import(/* webpackChunkName:'PurchaseSubscription' */ '../components/subscription/PurchaseSubscription'))
const ThankYou = React.lazy(() => import(/* webpackChunkName:'ThankYou' */ '../components/subscription/ThankYou'))
const Subscription = React.lazy(() => import(/* webpackChunkName:'Subscription' */ '../components/subscription/Subscription'))
const Oops = React.lazy(() => import(/* webpackChunkName:'Oops' */ '../components/subscription/Oops'))
const Users = React.lazy(() => import(/* webpackChunkName:'Users' */ '../components/admin/Users'))
const AdminWalks = React.lazy(() => import(/* webpackChunkName:'AdminWalks' */ '../components/admin/Walks'))
const Subscriptions = React.lazy(() => import(/* webpackChunkName:'Subscriptions' */ '../components/admin/Subscriptions'))
const AdminProfiles = React.lazy(() => import(/* webpackChunkName:'AdminProfiles' */ '../components/admin/Profiles'))
const User = React.lazy(() => import(/* webpackChunkName:'User' */ '../components/User'))

const App: React.FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const formName = location.pathname.split('/').slice(1, 3).join('-')
  const [appUpdateAvailable, setAppUpdateAvailable] = useState(false)
  const [appCachedOffline, setAppCachedOffline] = useState(false)
  const token = useSelector((state) => state.auth.token)
  const hasToken = useMemo(() => Boolean(token), [token])
  const isAdmin = useSelector((state) => state.user.self.role === 'admin')
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null)
  const lastFetchedAppConfig = useSelector(
    (state) => new Date(state.appConfig.lastFetchedAppConfig)
  )

  useEffect(() => {
    const getAppConfigIfNeeded = async () => {
      const oneDayAgo = subDays(new Date(), 1)
      if (token && oneDayAgo > lastFetchedAppConfig) {
        // only fetch config if it's stale
        await getAppConfig(dispatch)
      }
    }
    setInterval(() => {
      getAppConfigIfNeeded()
    }, 60000)
    getAppConfigIfNeeded()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClickAppUpdate = () => {
    setAppUpdateAvailable(false)
    registration?.waiting?.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload()
  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/requestreset" element={<RequestReset />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route
          path="/walks"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/walks',
              }}
              isAuthenticated={hasToken}
            >
              <Walks />
            </RequireAuth>
          }
        />
        <Route
          path="/walk/:slug/*"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/walks',
              }}
              isAuthenticated={hasToken}
            >
              <Walk form={formName} />
            </RequireAuth>
          }
        />
        <Route
          path="/stats/walks"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/stats/walks',
              }}
              isAuthenticated={hasToken}
            >
              <Stats />
            </RequireAuth>
          }
        />
        <Route
          path="/stats/walk/:slug"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/stats/walks',
              }}
              isAuthenticated={hasToken}
            >
              <WalkStats />
            </RequireAuth>
          }
        />
        <Route
          path="/account"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/account',
              }}
              isAuthenticated={hasToken}
            >
              <Account />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/profile',
              }}
              isAuthenticated={hasToken}
            >
              <EditProfile />
            </RequireAuth>
          }
        >
          <Route path=":slug" />
        </Route>
        <Route
          path="/profiles"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/profiles',
              }}
              isAuthenticated={hasToken}
            >
              <Profiles />
            </RequireAuth>
          }
        />
        <Route
          path="/subscription/purchase"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/subscription/purchase',
              }}
              isAuthenticated={hasToken}
            >
              <PurchaseSubscription />
            </RequireAuth>
          }
        />
        <Route
          path="/subscription/thankyou"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/subscription/thankyou',
              }}
              isAuthenticated={hasToken}
            >
              <ThankYou />
            </RequireAuth>
          }
        />
        <Route
          path="/subscription/oops"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/subscription/oops',
              }}
              isAuthenticated={hasToken}
            >
              <Oops />
            </RequireAuth>
          }
        />
        <Route
          path="/subscription/:slug"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/',
              }}
              isAuthenticated={hasToken}
            >
              <Subscription />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/users"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/admin/users',
              }}
              isAuthenticated={isAdmin && hasToken}
            >
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/user/:slug"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/admin/user/:slug',
              }}
              isAuthenticated={isAdmin && hasToken}
            >
              <User />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/walks"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/admin/walks',
              }}
              isAuthenticated={isAdmin && hasToken}
            >
              <AdminWalks />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/subscriptions"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/admin/subscriptions',
              }}
              isAuthenticated={isAdmin && hasToken}
            >
              <Subscriptions />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/profiles"
          element={
            <RequireAuth
              redirectTo="/login"
              state={{
                referrer: '/admin/profiles',
              }}
              isAuthenticated={isAdmin && hasToken}
            >
              <AdminProfiles />
            </RequireAuth>
          }
        />
        <Route
          path="/collection/:slug"
          element={<Navigate to="/walk/:slug" />}
        />
        <Route path="/" element={<Navigate to="/walks" />} />
      </Routes>
      <Toast isOpen={appUpdateAvailable} className="fixed-bottom m-3">
        <ToastHeader>Update available</ToastHeader>
        <ToastBody>
          <p>
            There is an update available for the Creator. Refresh to get it.
          </p>
          <Button color="primary" onClick={() => handleClickAppUpdate()}>
            Refresh
          </Button>
        </ToastBody>
      </Toast>
      <Alert isOpen={appCachedOffline} className="fixed-bottom m-3">
        Creator cached offline
      </Alert>
    </Suspense>
  )
}

type RequireAuthProps = {
  children?: ReactNode
  redirectTo: string
  state: {
    referrer: string
  }
  isAuthenticated: boolean
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  redirectTo,
  isAuthenticated,
}) => {
  return isAuthenticated ? <>{children}</> : <Navigate to={redirectTo} />
}

export default App
