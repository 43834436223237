export enum SubscriptionType {
  none = 'none',
  essential = 'essential',
  extra = 'extra'
}

export const subscriptionTypeIndex = [
  SubscriptionType.none,
  SubscriptionType.essential,
  SubscriptionType.extra
]
