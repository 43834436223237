import { distance, polygonToLine, pointToLineDistance, point } from '@turf/turf'
import { Point, Feature, LineString } from 'geojson'
import { Echo, EchoLocationStatus, TriggerType, EchoStatus } from './echo'


export enum EngineState {
  started,
  paused,
  stopped,
}

export enum EngineActivityState {
  activePlaying,
  activeStopped,
  outsideAllEchoes,
  outsideButStillPlaying,
}

export default class EchoesEngine implements IEchoesEngine {

  static shared: IEchoesEngine = new EchoesEngine()

  audioContext?: AudioContext = undefined

  locationUpdateTimer = 0

  echoes: Echo[] = []
  beaconRegions: Record<string, unknown>[] = []

  currentLocation?: Feature<Point>
  lastSignificantLocation?: Feature<Point>

  hasBeaconEchoes = false
  hasLocationEchoes = true

  nearbyPlayerLoadDistance = 100.0

  state: EngineState = EngineState.stopped
  activityState: EngineActivityState = EngineActivityState.outsideAllEchoes

  defaultFade = 2000

  lastLocationUpdate?: Date
  lastBeaconUpdate?: Date

  _locationCallback = (position: Feature<Point>) => {
    console.info(position)
  }

  prepare(echoes: IEchoModel[], offline = false): void {
    this.echoes = []
    // @TODO: add in the becaon regions here
    if (echoes.length > 0) {
      echoes.forEach(echoModel => {
        const echo = new Echo(echoModel, offline)
        this.echoes.push(echo)
      })
      this.hasBeaconEchoes = this.echoes.some(e => e.model.trigger === TriggerType.beacon)
      this.hasLocationEchoes = this.echoes.some(e => e.model.trigger === TriggerType.location)
    }
  }

  /**
   * Starts the Echoes Engine
   * Start location updates, beacon ranging for the available beacons, and resumes all players if necessary
   */
  async start(simulate = false): Promise<void> {
    this.audioContext = window.AudioContext ? new window.AudioContext() : new window.webkitAudioContext()
    // prepare the audio context
    if (this.audioContext.state === 'suspended') {
      await this.audioContext.resume()
    }
    // resume players if necessary
    this.resumeAllPlayers(0.5)
    // Location echoes
    if (!simulate && this.hasLocationEchoes) {
      this._startUpdatingLocation()
    }

    // Becaon echoes
    if (this.hasBeaconEchoes) {
      // @TODO: start ranging for beacons using worker
    }

    this.state = EngineState.started
  }

  /**
   * Pauses the Echoes Engine
   */
  pause(): void {
    this._stopUpdatingLocation()

    this.pauseAllPlayers(0.2, true)

    this.state = EngineState.paused
  }

  /**
   * Stops the Echoes Engine
   * Will stop updating location, beacon ranging and stop all players
   */
  async stop(): Promise<void> {
    // stop the audio context
    if (this.audioContext && this.audioContext.state === 'running') {
      await this.audioContext.suspend()
    }

    this._stopUpdatingLocation()

    this.stopAllPlayers(0.5, true)

    this.state = EngineState.stopped
  }

  /**
   * Unloads all players and clears the collections
   * Useful when leaving walk map view
   */
  unload(): void {
    this.unloadAllPlayers()
    this.echoes = []
  }

  _startUpdatingLocation(): void {
    // get current geolocation
    if ('geolocation' in navigator) {
      this.locationUpdateTimer = navigator.geolocation.watchPosition(position => {
        const p = point([position.coords.longitude, position.coords.latitude])
        this.__didUpdateLocations(p)
      }, error => {
        console.error(error)
        console.error('Could not get location')
      }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      })
    }
  }

  _stopUpdatingLocation(): void {
    if (this.locationUpdateTimer) {
      if ('geolocation' in navigator) {
        navigator.geolocation.clearWatch(this.locationUpdateTimer)
      }
    }
  }

  /**
   * Primary beacon location manager
   */
  _didUpdateBeacons(): void {
    // @TODO: Must implekment beacon locations
  }

  /**
   * Primary location manager
   */
  __didUpdateLocations = (position: Feature<Point>): void => {
    this._locationCallback && this._locationCallback(position)

    if (position.geometry) {
      this.currentLocation = position

      this.updateEngineLocation()
    }
  }

  updateLocationManually = (position: IEchoesPosition): void => {
    this.currentLocation = point([position.coords[0], position.coords[1]])
    this.updateEngineLocation()
  }

  updateEngineLocation(): void {
    if (!this.currentLocation) {
      return
    }
    if (!this.lastSignificantLocation) {
      this.lastSignificantLocation = this.currentLocation
    }

    if (distance(this.lastSignificantLocation, this.currentLocation) / 1000 > 30.0) {
      // reset the last significant location
      this.lastSignificantLocation = this.currentLocation
      // if we move more than 10 metres, let's reevaluate whether we need to load more players
      this.loadNearbyPlayers()
    }

    this.triggerEchoes(this.currentLocation)
  }

  /**
   * Trigger any echoes which are able to be triggered by a location
   */

  triggerEchoes(location: Feature<Point>): void {
    // check if we have any location echoes first
    if (!this.hasLocationEchoes) { return }

    const now = new Date()
    let locationUpdateInterval = 0.0
    if (this.lastLocationUpdate) {
      // calculate the difference in seconds from the nanoseconds between the last location update and now
      locationUpdateInterval = now.getTime() - this.lastLocationUpdate.getTime()
    }
    this.lastLocationUpdate = now
    // loop through the echoes to check if we're inside them
    this._changeEchoesStateUsing(location)

    // activate all the location echoes we're inside first
    const echoesInside = this.echoes.filter(e => (e.model.trigger === TriggerType.location || e.model.trigger === TriggerType.logic) && e.locStatus === EchoLocationStatus.inside)
    echoesInside.forEach(echo => {
      if (this.shouldTrigger(echo)) {
        echo.trigger(location, echo.model.trigger, locationUpdateInterval)
      }
    })

    // now try to deactivate the location echoes we're still outside
    const echoesOutside = this.echoes.filter(e => e.model.trigger === TriggerType.location && e.locStatus === EchoLocationStatus.outside)
    echoesOutside.forEach(echo => {
      echo.detrigger()
    })
    // @TODO: must post that we're outside all echoes
    this.updatePlayState()

  }

  _changeEchoesStateUsing(location: Feature<Point>): void {
    this.echoes.forEach(echo => {
      if (echo.locationIsInside(location)) {
        echo.locStatus = EchoLocationStatus.inside
      } else {
        echo.locStatus = EchoLocationStatus.outside
      }
    })
  }

  triggerEchoesWithBeacon(beacon: IBeacon): void {
    // only continue if we have beacon echoes
    if (!this.hasBeaconEchoes) { return }

    // only get the beacon echoes that match this beacon
    const beaconEchoes = this.echoes.filter(e => e.model.trigger === TriggerType.beacon && e.model.prox_uuid === beacon.proximityUUID && e.model.prox_major_id === beacon.major && e.model.prox_minor_id === beacon.minor)
    // don't continue if we don't have any matching echoes
    if (beaconEchoes.length < 1) { return }

    // get the beacons we're inside
    beaconEchoes.forEach(echo => {
      // @TODO: check that the prox trig dist matches what we get from the beacon
      if (echo.locStatus === EchoLocationStatus.outside && beacon.accuracy < echo.model.prox_trig_dist) {
        echo.locStatus = EchoLocationStatus.inside
      } else if (echo.locStatus === EchoLocationStatus.inside) {
        echo.locStatus = EchoLocationStatus.outside
      }
    })

    // activate all the beacon echoes we're inside
    const echoesInside = beaconEchoes.filter(e => e.locStatus === EchoLocationStatus.inside)
    echoesInside.forEach(echo => {
      echo.triggerWithBeacon()
    })

    const echoesOutside = beaconEchoes.filter(e => e.locStatus === EchoLocationStatus.outside)
    echoesOutside.forEach(echo => {
      echo.detrigger()
    })

    this.updatePlayState()
  }

  shouldTrigger(echo: Echo): boolean {
    let shouldTrigger = false
    if (echo.model.trigger === TriggerType.logic || echo.model.logic?.active) {
      // it's a logic-type trigger, get the logic structure
      const logic = echo.model.logic
      if (logic && logic.trigger) {
        // get the triggers, can be array or object/dictionary
        const trigger = logic.trigger as IEchoLogicStructure & IEchoLogicItem
        if (trigger._and) {
          const _and = trigger._and as IEchoLogicItem[]
          if (_and) {
            shouldTrigger = _and.map(eLI => {
              return this.checkEchoLogicItem(eLI)
            }).reduce<boolean | null>((prev, _and) =>
              (prev === null) ?
                _and :
                prev && _and
              , null) || false
          }
        } else if (trigger) {
          // if it's a simple logic , we have to satisfy
          shouldTrigger = this.checkEchoLogicItem(trigger)
        }
      }
      return shouldTrigger
    }
    // default to always triggering
    return true
  }

  checkEchoLogicItem(echoLogicItem: IEchoLogicItem): boolean {
    const shouldTriggers: boolean[] = []

    // check if any of the echoes has played already
    let _echoHasPlayedIns: string[] = []
    if ((echoLogicItem._echo_has_played as EchoLogicIn)) {
      _echoHasPlayedIns = (echoLogicItem._echo_has_played as EchoLogicIn)._in
    } else {
      _echoHasPlayedIns = (echoLogicItem._echo_has_played as string[])
    }
    if (_echoHasPlayedIns) {
      shouldTriggers.push(_echoHasPlayedIns.map(_in => {
        const echo = this.echoes.find(e => e.model._id === _in)
        if (echo) {
          return echo.playedCount() > 0
        }
        return false
      }).reduce((prev, _in) => prev || _in, false))
    }

    // check if any of the echoes has triggered already
    let _echoHasTriggeredIns: string[] = []
    if ((echoLogicItem._echo_has_triggered as EchoLogicIn)) {
      _echoHasTriggeredIns = (echoLogicItem._echo_has_triggered as EchoLogicIn)._in
    } else {
      _echoHasTriggeredIns = (echoLogicItem._echo_has_triggered as string[])
    }
    if (_echoHasTriggeredIns) {
      shouldTriggers.push(_echoHasTriggeredIns.map(_in => {
        const echo = this.echoes.find(e => e.model._id === _in)
        if (echo) {
          return echo.triggeredCount > 0
        }
        return false
      }).reduce((prev, _in) => prev || _in, false))
    }

    // check if any of the echoes has not played already
    let _echoHasNotPlayedIns: string[] = []
    if ((echoLogicItem._echo_has_not_played as EchoLogicIn)) {
      _echoHasNotPlayedIns = (echoLogicItem._echo_has_not_played as EchoLogicIn)._in
    } else {
      _echoHasNotPlayedIns = (echoLogicItem._echo_has_not_played as string[])
    }
    if (_echoHasNotPlayedIns) {
      shouldTriggers.push(_echoHasNotPlayedIns.map(_in => {
        const echo = this.echoes.find(e => e.model._id === _in)
        if (echo) {
          return echo.playedCount() == 0
        }
        return false
      }).reduce((prev, _in) => prev || _in, false))
    }

    // check if any of the echoes has triggered already
    let _echoHasNotTriggeredIns: string[] = []
    if ((echoLogicItem._echo_has_not_triggered as EchoLogicIn)) {
      _echoHasNotTriggeredIns = (echoLogicItem._echo_has_not_triggered as EchoLogicIn)._in
    } else {
      _echoHasNotTriggeredIns = (echoLogicItem._echo_has_not_triggered as string[])
    }
    if (_echoHasNotTriggeredIns) {
      shouldTriggers.push(_echoHasNotTriggeredIns.map(_in => {
        const echo = this.echoes.find(e => e.model._id === _in)
        if (echo) {
          return echo.triggeredCount == 0
        }
        return false
      }).reduce((prev, _in) => prev || _in, false))
    }

    // if we have satisfied any demands, return true
    return shouldTriggers.reduce((prev, trigger) => prev || trigger, false)
  }

  updatePlayState(): void {
    const activeEchoes = this.getActiveEchoes()
    const playingEchoes = this.getPlayingEchoes()
    if (playingEchoes.length === 0 && activeEchoes.length === 0 && this.activityState !== EngineActivityState.outsideAllEchoes) {
      this.activityState = EngineActivityState.outsideAllEchoes
      // @TODO must post change state somehow
    } else if (activeEchoes.length === 0 && playingEchoes.length > 0) {
      // we're outside an echo but still playing…
      this.activityState = EngineActivityState.outsideButStillPlaying
    } else if (activeEchoes.length > 0 && playingEchoes.length === 0 && this.activityState !== EngineActivityState.activeStopped) {
      // we're in an echo but playback has stopped
      this.activityState = EngineActivityState.activeStopped
    } else if (activeEchoes.length > 0 && this.activityState !== EngineActivityState.activePlaying) {
      // we're still playing
      this.activityState = EngineActivityState.activePlaying
      // @TODO: must post change state somehow
    }
  }

  /**
   * Get any active echoes
   * 
   * These will be Echoes which have been triggered by any means
   * This does not return currently playing Echoes
   * 
   */
  getActiveEchoes(): Echo[] {
    return this.echoes.filter(e => e.status === EchoStatus.active)
  }

  getPlayingEchoes(): Echo[] {
    return this.echoes.filter(e => e.isPlaying())
  }

  getOutsideEchoes(): Echo[] {
    return this.echoes.filter(e => e.locStatus === EchoLocationStatus.outside)
  }

  /**
   * Load all the players
   * 
   * This loads all the players, loading all sounds into memory
   * When streaming large walks it's better to load nearby echoes only
   */
  loadAllPlayers(): void {
    this.echoes.forEach(echo => {
      if (!echo.isLoaded) {
        echo.loadPlayers()
      }
      if (this.echoes.filter(e => !e.isLoaded).length === 0) {
        console.info('All players loaded')
      }
    })
  }

  /**
   * Unload all the players
   */
  async unloadAllPlayers(): Promise<void> {
    if (this.echoes.length === 0) {
      return
    }
    this.echoes.forEach(echo => {
      echo.unloadPlayers()
    })
  }

  /**
   * Load all nearby players
   * 
   * This will load all players within a specified distance.
   * Should be called sparingly to avoid frequent player load/unloads
   */
  loadNearbyPlayers(): void {
    this.echoes.forEach(echo => {
      if (this.currentLocation && !echo.isLoaded && echo.locationIsInside(this.currentLocation)) {
        // we have a location and we're already inside the echo
        echo.loadPlayers()
      } else {
        if (!this.currentLocation) {
          return
        }
        // only load the players if they're not already loaded
        let distanceToEcho = 0.0
        let shape = 'polygon'
        if (echo.isPolygon() && echo.polygon) {
          const line = polygonToLine(echo.polygon)
          distanceToEcho = pointToLineDistance(this.currentLocation, line as Feature<LineString>) / 1000 // in km
        } else {
          shape = 'circle'
          // it's a circle
          // radius is in metres
          const distanceToCircleCentre = distance(this.currentLocation, echo.centreCoords)
          distanceToEcho = distanceToCircleCentre - echo.model.radius
        }
        const isEchoNearby = distanceToEcho < this.nearbyPlayerLoadDistance
        if (!echo.isLoaded && isEchoNearby) {
          echo.loadPlayers()
          console.info(`loaded sounds for ${shape} ${echo.titleText}`)
        } else if (!echo.isPlaying() && echo.isLoaded && !isEchoNearby) {
          // unload the player as it's too far away
          echo.unloadPlayers()
          console.info(`unloaded sounds for ${shape} ${echo.titleText}`)
        }
      }
    })
  }

  /**
   * Stop all the players
   * 
   * Useful when exiting the walk map
   * 
   * @param fade Fade out length
   * @param force Force stop the echo - they will continue playing if 'play_complete' is true otherwise
   */
  stopAllPlayers(fade: number, force = false): void {
    this.echoes.forEach(echo => echo.stopPlayers(fade, force))
  }

  /** 
   * Pause all the players
   * 
   * Useful when clicking the pause button, should be ready to resume
   * 
   * @param fade Fade out length
   * @param force Force stop the echo - they will continue playing if 'play_complete' is true otherwise
   */
  pauseAllPlayers(_: number, force = false): void {
    this.echoes.forEach(echo => echo.pause(force))
  }

  /**
   * Resume all the players
   * 
   * Useful when clicking the play button
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resumeAllPlayers(_fade: number): void {
    const activeEchoes = this.getActiveEchoes()
    activeEchoes.forEach(echo => {
      echo.play(1.0)
    })
  }

}
